import { useTranslation } from "react-i18next";
import Table from "../../list/Table";
import {
	formatDate,
	formatMedia,
	more,
	translateCell,
} from "../../providers/format";
import { CreatedBy, Loader } from "../../template";
import { RiFileExcelLine } from "react-icons/ri";
import { useState } from "react";
import { mainApi } from "../../providers/api";
import { format } from "date-fns";
import { DEBUG } from "../../defines";
import * as XLSX from "xlsx";

const EnrollmentList = () => {
	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);

	const onClickDownload = async () => {
		try {
			setLoading(true);
			const response = await mainApi({
				method: "POST",
				url: `/enrollment/export`,
			});

			await exportToExcel(
				format(new Date(), "yyyy-MM-dd HH:mm:ss"),
				response.data
			);
		} catch (error) {
			DEBUG && console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const exportToExcel = async (filename, rows) => {
		const header = [
			"Бүртгүүлсэн огноо",
			"Код",
			"Регистр",
			"Овог",
			"Нэр",
			"Утас",
			"Имэйл",
			"Хаяг",
			"Шалгалтанд дагуулж ирэх",
			"Шалгалтын огноо",
			"Бүртгэлийн төлөв",
			"Баталгаажуулсан огноо",
		];

		const data = rows.map(
			({
				created,
				code,
				register,
				firstname,
				lastname,
				phone,
				email,
				address,
				enrollment_parent,
				exam_date,
				status,
				verified,
			}) => [
				created && format(new Date(created), "yyyy-MM-dd HH:mm:ss"),
				code,
				register,
				firstname,
				lastname,
				phone,
				email,
				address,
				enrollment_parent,
				exam_date && format(new Date(exam_date), "yyyy-MM-dd HH:mm"),
				status === "verified" ? "Баталгаажсан" : "Хянагдаагүй",
				verified && format(new Date(verified), "yyyy-MM-dd HH:mm:ss"),
			]
		);

		let wb = XLSX.utils.book_new();
		let ws = XLSX.utils.json_to_sheet([header, ...data], {
			skipHeader: true,
		});

		ws["!cols"] = header.map(() => ({
			wch: 25,
		}));

		XLSX.utils.book_append_sheet(wb, ws);

		return XLSX.writeFile(wb, `${filename}.xlsx`);
	};

	return (
		<div className="relative">
			{loading && <Loader />}
			<Table
				renderHeader={() => (
					<div className="flex justify-end">
						<div className="mb-2">
							<button
								type="button"
								onClick={() => {
									onClickDownload();
								}}
								className="button gray"
							>
								2024 XLSX <RiFileExcelLine className="text-lg ml-1" />
							</button>
						</div>
					</div>
				)}
				url={"/enrollment/table"}
				columns={[
					{
						key: "image",
						label: "field.image",
						render: (row) => formatMedia(row, "image", "title", "w-24"),
					},
					{
						key: "status",
						label: "field.status",
						sortable: true,
						filter: {
							type: "select",
							axio: "/const/static/enrollment_status",
							axioAdapter: (data) => {
								return data.map(({ _id }) => ({
									value: _id,
									label: t(`const.enrollment_status.${_id}`),
								}));
							},
						},
						render: (row) =>
							translateCell(row, t, "enrollment_status", "status"),
					},
					{
						key: "code",
						label: "field.code",
						sortable: true,
						filter: true,
					},
					{
						key: "register",
						label: "field.register",
						sortable: true,
						filter: true,
					},
					{
						key: "lastname",
						label: "field.lastname",
						sortable: true,
						filter: true,
					},
					{
						key: "firstname",
						label: "field.firstname",
						sortable: true,
						filter: true,
					},
					{
						key: "phone",
						label: "field.phone",
						sortable: true,
						filter: true,
					},
					{
						key: "email",
						label: "field.email",
						sortable: true,
						filter: true,
					},
					{
						key: "exam_date",
						label: "field.exam_date",
						sortable: true,
						width: 200,
						filter: { type: "exam_date" },
						render: (row) => formatDate(row, "exam_date", "yyyy-MM-dd"),
					},
					{
						label: "field.date",
						width: 250,
						render: ({ created }) => <CreatedBy date={created}></CreatedBy>,
					},
					{ render: ({ _id }) => more(`view/${_id}`) },
				]}
			></Table>
		</div>
	);
};

export default EnrollmentList;
