import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { Field, Form } from "../../template";
import { fileValidation, stringValidation } from "../../providers/validations";
import { FileUpload, Tabs, Text, Toggle } from "../../inputs";
import { useApp } from "../../providers/app";
import { useTranslation } from "react-i18next";

const ArticleForm = ({ type }) => {
	const { id } = useParams();
	const { user } = useApp();
	const { t } = useTranslation();

	const props = {
		editable: user.role === "admin",
		isDeletable: user.role === "admin",
		model: "article",
		id,
		init: {
			type,
			published: true,
			title: "",
			thumb: [],
			media: [],
			attachments: [],
		},
		validationSchema: Yup.object().shape({
			title: stringValidation(true),
			thumb: fileValidation(),
			media: fileValidation(),
			attachments: fileValidation(),
		}),
	};

	return (
		<Form {...props}>
			{({ disabled, submited }) => {
				return (
					<div className="p-5 bg-white grid grid-cols-1 md:grid-cols-2 gap-8">
						<div className="flex flex-col gap-4">
							<h2 className="bordered">{t("title.article_info")}</h2>
							<Field
								name="published"
								label="field.published"
								disabled={disabled}
								component={Toggle}
							/>
							<Field
								name="type"
								label="field.type"
								disabled={disabled}
								component={Tabs}
								axio="/const/static/article_type"
								axioAdapter={(data) => {
									return data.map(({ _id }) => ({
										value: _id,
										label: t(`const.article_type.${_id}`),
									}));
								}}
							/>
							<Field
								name="title"
								label="field.title"
								disabled={disabled}
								component={Text}
							/>
							<Field
								name="trans.ru.title"
								label="field.title_ru"
								disabled={disabled}
								component={Text}
							/>
							<Field
								name="trans.us.title"
								label="field.title_us"
								disabled={disabled}
								component={Text}
							/>
							<Field
								name="content"
								label="field.content"
								disabled={disabled}
								component={Text}
								type="textarea"
								rows={5}
							/>
							<Field
								name="trans.ru.content"
								label="field.content_ru"
								disabled={disabled}
								component={Text}
								type="textarea"
								rows={5}
							/>
							<Field
								name="trans.us.content"
								label="field.content_us"
								disabled={disabled}
								component={Text}
								type="textarea"
								rows={5}
							/>
						</div>
						<div className="flex flex-col gap-4">
							<Field
								name="thumb"
								label="field.thumb"
								disabled={disabled}
								submited={submited}
								component={FileUpload}
								limit={5}
								root="article"
							/>
							<Field
								name="media"
								label="field.media"
								disabled={disabled}
								submited={submited}
								component={FileUpload}
								root="article"
								limit={5}
								count={10}
							/>
							<Field
								name="attachments"
								label="field.attachments"
								disabled={disabled}
								submited={submited}
								component={FileUpload}
								preview={false}
								root="article"
								limit={5}
								count={20}
								accept={{ "application/pdf": [".pdf"] }}
							/>
						</div>
					</div>
				);
			}}
		</Form>
	);
};

export default ArticleForm;
