import { useTranslation } from "react-i18next";
import { HiPlus } from "react-icons/hi";
import { Link } from "react-router-dom";
import Table from "../../list/Table";
import { useApp } from "../../providers/app";
import { more, translateCell } from "../../providers/format";

const defaultFind = ({ role, ...user }) => {
	if (role === "admin") return {};
	if (role === "teacher")
		return { $or: [{ createdby: user.id }, { type: "school" }] };
	if (role === "student")
		return {
			class: { $in: [null, user.class?.id] },
			roles: "student",
			start: { $lte: new Date() },
			end: { $gte: new Date() },
		};
	if (role === "parent" && Array.isArray(user.children))
		return {
			class: {
				$in: [
					null,
					...user.children.map((child) => child?.student?.class?._id),
				],
			},
			roles: "parent",
			start: { $lte: new Date() },
			end: { $gte: new Date() },
		};
	return { _id: null };
};

const PollList = () => {
	const { t } = useTranslation();
	const { user } = useApp();

	const supervisor = ["admin", "teacher"].includes(user.role);

	return (
		<Table
			url={"/poll/table"}
			defaultFind={defaultFind(user)}
			renderHeader={
				["admin", "teacher"].includes(user.role)
					? () => {
							return (
								<div className="flex justify-end">
									<div className="mb-4">
										<Link to="new" className="button">
											{t("button.create_poll")}
											<HiPlus className="text-lg ml-1" />
										</Link>
									</div>
								</div>
							);
					  }
					: null
			}
			columns={[
				{
					key: "type",
					label: "field.type",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/static/sigment_type",
						axioAdapter: (data) => {
							return data.map(({ _id }) => ({
								value: _id,
								label: t(`const.sigment_type.${_id}`),
							}));
						},
					},
					render: (row) => translateCell(row, t, "sigment_type", "type"),
				},
				{
					key: "class",
					label: "field.class",
					filter: {
						type: "select",
						axio: "/const/select/class",
						labelField: "current",
					},
					render: ({ class: _class }) => {
						if (Array.isArray(_class))
							return _class.map((c) => c.current).join(", ");
						return _class?.current;
					},
				},
				{
					key: "roles",
					label: "field.role",
					render: ({ roles }) =>
						Array.isArray(roles) &&
						roles.map((role) => translateCell(role, t, "role")).join(", "),
				},
				{
					key: "title",
					label: "field.title",
					width: 300,
					sortable: true,
					filter: true,
				},
				{
					key: "start",
					label: "field.start",
					sortable: true,
					width: 200,
					filter: { type: "date" },
					render: ({ _start }) => _start,
				},
				{
					key: "end",
					label: "field.end",
					sortable: true,
					width: 200,
					filter: { type: "date" },
					render: ({ _end }) => _end,
				},
				supervisor && {
					key: "options",
					label: "field.options",
					render: ({ options, total }) => {
						if (Array.isArray(options))
							return (
								<div className="whitespace-nowrap">
									{options.map(({ text, voted }) => (
										<div key={text} className="flex">
											<div className="w-24">
												{parseFloat((voted / total) * 100).toFixed(2)}% ({voted}
												)
											</div>
											<div className="ml-2 truncate" style={{ maxWidth: 500 }}>
												{text}
											</div>
										</div>
									))}
								</div>
							);
					},
				},
				{ render: ({ _id }) => more(`view/${_id}`) },
			]}
		></Table>
	);
};

export default PollList;
