import objectPath from "object-path";
import { useTranslation } from "react-i18next";
import { HiPlus, HiPencil } from "react-icons/hi";
import { Link } from "react-router-dom";

const COLS = [
	{
		label: "field.relation",
		key: "relation",
		render: (t, value) => t(`const.relation.${value}`),
	},
	{ label: "field.code", key: "parent.code" },
	{ label: "field.lastname", key: "parent.lastname" },
	{ label: "field.firstname", key: "parent.firstname" },
	{ label: "field.phone", key: "parent.phone" },
];

const ParentList = ({ class_id, disabled, student_id, parents = [] }) => {
	const { t } = useTranslation();

	return (
		<div className="w-full">
			<h1>{t("field.parent")}</h1>
			<table className="table-auto w-full my-4">
				<tbody>
					<tr>
						{COLS.map(({ label, key }) => {
							return (
								<td key={key} className="border p-2">
									{t(label)}
								</td>
							);
						})}
						{!disabled && <td className="border p-2"></td>}
					</tr>
				</tbody>
				<tbody>
					{parents.map((doc) => {
						return (
							<tr key={doc._id}>
								{COLS.map(({ key, render }) => {
									return (
										<td key={key} className="border p-2">
											{render
												? render(t, objectPath.get(doc, key))
												: objectPath.get(doc, key)}
										</td>
									);
								})}
								{!disabled && (
									<td className="border p-2">
										<Link
											to={`/app/class/view/${class_id}/student/view/${student_id}/parent/view/${doc?.parent?._id}`}
											className="button"
										>
											<HiPencil className="text-lg" />
										</Link>
									</td>
								)}
							</tr>
						);
					})}
				</tbody>
			</table>

			{!disabled && (
				<Link
					to={`/app/class/view/${class_id}/student/view/${student_id}/parent/add`}
					className="button"
				>
					{t("button.add")}
					<HiPlus className="text-lg ml-1" />
				</Link>
			)}
		</div>
	);
};

export default ParentList;
