import { useTranslation } from "react-i18next";
import StudentForm from "../components/user/StudentForm";
import ParentForm from "../components/user/ParentForm";
import EmployeeForm from "../components/user/EmployeeForm";
import LanguageSwitch from "../language/Switch";
import { useApp } from "../providers/app";
import YearControl from "./YearControl";

const Settings = ({ type }) => {
	const { t } = useTranslation();
	const { user } = useApp();

	return (
		<div className="">
			<h2 className="bordered">{t(`title.${type}`)}</h2>
			<div className="p-5 bg-white my-5">
				<LanguageSwitch />
			</div>
			<div>
				{user.role === "student" && (
					<StudentForm
						student_id={user.id}
						profile={true}
						hideStudent={true}
					></StudentForm>
				)}
				{user.role === "parent" && (
					<ParentForm user_id={user.id} profile={true}></ParentForm>
				)}
				{["admin", "teacher"].includes(user.role) && (
					<EmployeeForm user_id={user.id} profile={true}></EmployeeForm>
				)}
				{user.role === "admin" && (
					<div className="mt-5">
						<YearControl></YearControl>
					</div>
				)}
			</div>
		</div>
	);
};

export default Settings;
