import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { Field, Form, Loader } from "../../template";
import { fileValidation, stringValidation } from "../../providers/validations";
import { DatePicker, FileUpload, Tabs, Text, Toggle } from "../../inputs";
import { useApp } from "../../providers/app";
import { useTranslation } from "react-i18next";
import { RiDownload2Line, RiSendPlaneLine } from "react-icons/ri";
import { mainApi } from "../../providers/api";
import { useNavigate } from "react-router-dom";
import { successAlert, translateError } from "../../providers/alert";
import { useState } from "react";
import { API_ROOT } from "../../defines";

const EnrollmentForm = () => {
	const { id } = useParams();
	const { user } = useApp();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);

	const props = {
		editable: user.role === "admin",
		isDeletable: user.role === "admin",
		model: "enrollment",
		id,
		init: {
			register: "",
			firstname: "",
			lastname: "",
			address: "",
			phone: "",
			email: "",
			phone: "",
			enrollment_parent: "",
			enrollment_passport: [],
			image: [],
			birth: [],
			payment: [],
		},
		validationSchema: Yup.object().shape({
			register: stringValidation(true),
			firstname: stringValidation(true),
			lastname: stringValidation(true),
			address: stringValidation(true),
			phone: stringValidation(true),
			email: stringValidation(true),
			enrollment_parent: stringValidation(true),
			enrollment_passport: fileValidation(true),
			image: fileValidation(true),
			birth: fileValidation(true),
			payment: fileValidation(true),
		}),
	};

	const downloadPage = async (name, url) => {
		try {
			fetch(API_ROOT + "/" + url, {
				method: "GET",
				headers: {
					"Content-Type": "application/pdf",
				},
			})
				.then((response) => response.blob())
				.then((blob) => {
					const url = window.URL.createObjectURL(new Blob([blob]));

					const link = document.createElement("a");
					link.href = url;
					link.download = name + ".pdf";

					document.body.appendChild(link);

					link.click();
					link.parentNode.removeChild(link);
				});
		} catch (error) {}
	};

	const verify = async (exam_date) => {
		try {
			if (!exam_date) throw new Error("Шалгалтын огноо сонгоно уу");
			setLoading(true);
			const response = await mainApi({
				url: `/enrollment/verify/${id}`,
				method: "PUT",
				data: { exam_date },
			});

			successAlert();
			setLoading(false);
			navigate(-1);
		} catch (error) {
			translateError(error);
		}
	};

	return (
		<Form {...props}>
			{({ disabled, submited, values }) => {
				return (
					<div className="p-5 bg-white grid grid-cols-1 md:grid-cols-2 gap-8">
						{loading && <Loader />}
						<div className="flex flex-col gap-4">
							<h2 className="bordered">{t("title.enrollment_info")}</h2>
							<Field
								disabled={true}
								name="code"
								label="field.code"
								component={Text}
							/>
							<Field
								disabled={disabled}
								name="register"
								label="field.register"
								component={Text}
							/>
							<Field
								disabled={disabled}
								name="lastname"
								label="field.lastname"
								component={Text}
							/>
							<Field
								disabled={disabled}
								name="firstname"
								label="field.firstname"
								component={Text}
							/>
							<Field
								disabled={disabled}
								name="phone"
								label="field.phone"
								component={Text}
								type="number"
							/>
							<Field
								disabled={disabled}
								name="email"
								label="field.email"
								component={Text}
							/>
							<Field
								disabled={disabled}
								name="address"
								label="field.address"
								component={Text}
								type="textarea"
								rows={5}
							/>
							<Field
								name="payment"
								label="field.payment"
								component={FileUpload}
								root="enrollment"
								disabled={disabled}
								submited={submited}
								limit={5}
							/>
							<Field
								disabled={disabled}
								name="enrollment_parent"
								label="field.enrollment_parent"
								component={Text}
								type="textarea"
								rows={5}
							/>
							<Field
								name="enrollment_passport"
								label="field.enrollment_passport"
								component={FileUpload}
								root="enrollment"
								disabled={disabled}
								submited={submited}
								limit={5}
							/>
						</div>
						<div className="flex flex-col gap-4">
							<Field
								name="image"
								label="field.image"
								component={FileUpload}
								root="enrollment"
								disabled={disabled}
								submited={submited}
								limit={5}
							/>
							<Field
								name="birth"
								label="field.birth"
								component={FileUpload}
								root="enrollment"
								disabled={disabled}
								submited={submited}
								limit={5}
							/>
							<Field
								name="exam_date"
								label="field.exam_date"
								component={DatePicker}
								time={true}
							/>
							<button
								type="button"
								onClick={() => {
									verify(values.exam_date);
								}}
								className="secondary"
							>
								Баталгаажуулах <RiSendPlaneLine className="text-lg ml-1" />
							</button>
							{values.page && (
								<button
									type="button"
									onClick={() => {
										downloadPage(values.code, values.page);
									}}
									className="secondary mt-2"
								>
									Шалгалтын хуудас татах{" "}
									<RiDownload2Line className="text-lg ml-1" />
								</button>
							)}
						</div>
					</div>
				);
			}}
		</Form>
	);
};

export default EnrollmentForm;
