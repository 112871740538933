import React, { useRef } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "../swiper.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

const MSwiper = ({ items = [], height = 400 }) => {
	const progressCircle = useRef(null);
	const progressContent = useRef(null);
	const onAutoplayTimeLeft = (s, time, progress) => {
		progressCircle.current.style.setProperty("--progress", 1 - progress);
		progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
	};

	return (
		<>
			<Swiper
				slidesPerView={window.innerWidth > 900 ? 3 : 1}
				spaceBetween={30}
				centeredSlides={true}
				autoplay={{
					delay: 5000,
					disableOnInteraction: false,
				}}
				pagination={{
					clickable: true,
					type: "progressbar",
				}}
				loop={true}
				navigation={true}
				modules={[Autoplay, Pagination, Navigation]}
				onAutoplayTimeLeft={onAutoplayTimeLeft}
				className="mySwiper"
			>
				{items.map((item, index) => {
					return (
						<SwiperSlide key={index}>
							<img
								crossOrigin="anonymous"
								className="object-cover object-bottom"
								style={{ height }}
								src={item}
								alt=""
							/>
						</SwiperSlide>
					);
				})}
				<div className="autoplay-progress" slot="container-end">
					<svg viewBox="0 0 48 48" ref={progressCircle}>
						<circle cx="24" cy="24" r="20"></circle>
					</svg>
					<span ref={progressContent}></span>
				</div>
			</Swiper>
		</>
	);
};

export default MSwiper;
