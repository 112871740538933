const BigTitile = ({ title, subtitle }) => {
	return (
		<div className="text-center my-10">
			<div className="text-4xl uppercase text-secondary-100">{title}</div>
			{subtitle && <div className="text-dark-60">{subtitle}</div>}
		</div>
	);
};

export default BigTitile;
