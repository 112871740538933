import { useTranslation } from "react-i18next";

const Meta = ({
	template,
	title,
	_start,
	_end,
	type,
	class: _class,
	roles,
}) => {
	const { t } = useTranslation();

	return (
		<div>
			<div>Судалгааны загвар: {template?.name}</div>
			<div>Судалгааны нэр: {title}</div>
			<div className="mt-5">Эхлэх огноо: {_start}</div>
			<div>Дуусах огноо: {_end}</div>
			<div className="mt-5">
				Судалгааны төрөл:{" "}
				{type === "class" ? "Ангиудаас" : "Сургуулийн хэмжээнд"}
			</div>
			{type === "class" && (
				<div>Анги: {_class.map(({ current }) => current).join(", ")}</div>
			)}
			<div>
				Хамрах хүрээ: {roles.map((role) => t(`const.role.${role}`)).join(", ")}
			</div>
		</div>
	);
};

export default Meta;
