import { useTranslation } from "react-i18next";
import { HiPlus } from "react-icons/hi";
import { Link } from "react-router-dom";
import Table from "../../list/Table";
import { more } from "../../providers/format";
import { CreatedBy } from "../../template";

const SurveyTemplateList = () => {
	const { t } = useTranslation();

	return (
		<Table
			url={"/survey-template/table"}
			renderHeader={() => {
				return (
					<div className="flex justify-end">
						<div className="mb-4">
							<Link to="new" className="button">
								{t("button.create_survey_template")}
								<HiPlus className="text-lg ml-1" />
							</Link>
						</div>
					</div>
				);
			}}
			columns={[
				{
					key: "name",
					label: "field.name",
					sortable: true,
					filter: true,
				},
				{
					label: "field.createdby",
					width: 250,
					render: ({ created, createdby }) => (
						<CreatedBy date={created} user={createdby}></CreatedBy>
					),
				},
				{ render: ({ _id }) => more(`view/${_id}`) },
			]}
		></Table>
	);
};

export default SurveyTemplateList;
