import { useTranslation } from "react-i18next";
import { APP_STORE, PLAY_STORE } from "../../defines";
import BigTitile from "./BigTitle";

const AppMockup = () => {
	const { t } = useTranslation();

	return (
		<div className="py-10 bg-white">
			<BigTitile
				title={t("label.app_title")}
				subtitle={t("label.app_subtitle")}
			></BigTitile>
			<img
				className="w-full object-cover"
				src="/images/app_mockup.png"
				alt="cover"
			/>
			<div className="flex justify-center gap-5 my-10">
				<a href={PLAY_STORE} target="_blank" rel="noreferrer">
					<img src="/images/playstore.png" alt="download" />
				</a>
				<a href={APP_STORE} target="_blank" rel="noreferrer">
					<img src="/images/appstore.png" alt="download" />
				</a>
			</div>
		</div>
	);
};
export default AppMockup;
