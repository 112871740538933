import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { RiArrowRightLine } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import EmployeeCard from "./Card";
import BigTitile from "../landing/BigTitle";
import axios from "axios";

const FeaturedEmployees = ({ featured_category = "russian", hideAll }) => {
	const { t } = useTranslation();
	const [teachers, setTeachers] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			const response = await axios({
				url: `public/teacher`,
				method: "POST",
				data: {
					find: {
						featured: true,
						active: true,
						featured_category: featured_category,
					},
					sort: { priority: 1 },
					limit: hideAll ? 1024 : 8,
				},
			});
			setTeachers(response.data);
		};

		fetchData();
	}, []);

	if (teachers.length === 0) return null;

	return (
		<div className="p-10 relative">
			<div className="container mx-auto">
				<img className="absolute bottom-0 left-0" src="/images/dots_blue.png" />
				<BigTitile
					title={t(`const.featured_teacher.${featured_category}`)}
				></BigTitile>
				<div className="grid grid-cols-1 md:grid-cols-4 gap-5 justify-around text-center text-secondary-100">
					{teachers.map((teacher) => (
						<EmployeeCard key={teacher.id} {...teacher} />
					))}
				</div>
				{!hideAll && (
					<div className="flex justify-center my-10">
						<Link to="/public/employee" className="link-button">
							{t("button.more")}
							<RiArrowRightLine className="ml-2" />
						</Link>
					</div>
				)}
			</div>
		</div>
	);
};
export default FeaturedEmployees;
