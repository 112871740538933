import { useEffect, useState } from "react";
import { Loader } from "../../template";
import { mainApi } from "../../providers/api";
import { useTranslation } from "react-i18next";
import Meta from "./Meta";
import { Text } from "../../inputs";
import { successAlert, translateError } from "../../providers/alert";
import { useNavigate } from "react-router-dom";

const UserView = ({ survey, userResponse }) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [answers, setAnswers] = useState([]);
	const navigate = useNavigate();

	useEffect(() => {
		if (userResponse) {
			setAnswers(
				survey.results.map((r, index) => ({
					...r,
					response: userResponse.answers[index].response,
				}))
			);
		} else {
			setAnswers(
				survey.results.map((r) => ({
					...r,
					response: r.type === "multiple_choices" ? [] : "",
				}))
			);
		}
	}, [userResponse]);

	const sendVote = async (data) => {
		try {
			answers.map(({ required, response }, index) => {
				if (required && (!response || response.length === 0))
					throw new Error(`${index + 1}-р асуултанд хариулна уу`);
			});
			setLoading(true);
			const response = await mainApi({
				method: "POST",
				url: "/survey/take",
				data: {
					id: survey.id,
					answers,
				},
			});
			await successAlert(null, "Судалгаанд идэвхитэй оролцсонд баярлалаа");
			navigate(-1);
		} catch (error) {
			translateError(error);
		} finally {
			setLoading(false);
		}
	};

	const change = (index, value, type) => {
		const tmp = [...answers];

		if (type === "multiple_choices") {
			const i = tmp[index].response.findIndex((m) => m === value);
			if (i > -1) {
				tmp[index].response.splice(i, 1);
			} else {
				tmp[index].response.push(value);
			}
		} else {
			tmp[index].response = value;
		}

		setAnswers(tmp);
	};

	return (
		<div className="relative p-5 bg-white">
			{loading && <Loader></Loader>}
			<Meta {...survey}></Meta>
			{userResponse && (
				<div className="mt-5">
					Судалгаанд хамрагдсан огноо: {userResponse._ended}
				</div>
			)}

			{answers &&
				answers.map(({ question, type, options, response }, index) => {
					return (
						<div key={index} className="my-5">
							<div>
								{index + 1}. {question}
							</div>
							<div className="mx-5">
								{type === "short_text" && (
									<Text
										value={response}
										disabled={userResponse}
										onChange={(v) => change(index, v)}
									></Text>
								)}
								{type === "long_text" && (
									<Text
										value={response}
										disabled={userResponse}
										onChange={(v) => change(index, v)}
										type="textarea"
										rows={5}
									></Text>
								)}
								{type === "number" && (
									<Text
										value={response}
										disabled={userResponse}
										onChange={(v) => change(index, v)}
										type="number"
									></Text>
								)}
								{["multiple_choices", "single_choice"].includes(type) && (
									<div className="text-left w-auto">
										{options.map((option, oIndex) => (
											<div key={oIndex} className="flex">
												<input
													name={question}
													disabled={userResponse}
													checked={
														type === "multiple_choices"
															? response.includes(option.option)
															: response === option.option
													}
													onChange={(e) => change(index, option.option, type)}
													type={
														type === "multiple_choices" ? "checkbox" : "radio"
													}
												></input>
												{option.option}
											</div>
										))}
									</div>
								)}
							</div>
						</div>
					);
				})}

			{!userResponse && (
				<div className="flex justify-end mt-4">
					<div>
						<button
							type="button"
							className="secondary"
							onClick={() => {
								sendVote();
							}}
						>
							{t("button.send")}
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

export default UserView;
