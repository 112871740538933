import FeaturedEmployees from "../components/employee/Featured";

const Employees = () => {
	return (
		<div className="container mx-auto bg-white p-2">
			<FeaturedEmployees
				hideAll={true}
				featured_category="russian"
			></FeaturedEmployees>
			<FeaturedEmployees
				hideAll={true}
				featured_category="mongolian"
			></FeaturedEmployees>
			<FeaturedEmployees
				hideAll={true}
				featured_category="foreign"
			></FeaturedEmployees>
		</div>
	);
};

export default Employees;
