import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { Field, Form } from "../../template";
import {
	selectValidation,
	stringValidation,
} from "../../providers/validations";
import { DatePicker, Select, Tabs, Text } from "../../inputs";
import { useApp } from "../../providers/app";
import { useTranslation } from "react-i18next";
import AdminView from "./AdminView";
import UserView from "./UserView";

const SurveyForm = () => {
	const { id } = useParams();
	const { user } = useApp();
	const { t } = useTranslation();

	const props = {
		editable: user.role === "admin",
		isDeletable: user.role === "admin",
		model: "survey",
		id,
		init: {
			title: "",
			start: null,
			end: null,
			template: null,
			roles: [],
			type: "",
			class: null,
			completed: 0,
		},
		validationSchema: Yup.object().shape({
			start: stringValidation(true),
			end: stringValidation(true),
			title: stringValidation(true),
			template: selectValidation(true),
			type: stringValidation(true),
		}),
		beforeSubmit: (values) => {
			if (values.type === "class" && !values.class)
				throw new Error("Анги сонгоно уу");

			return values;
		},
		view: (row) => {
			if (row.survey)
				return user.role === "admin" ? (
					<AdminView {...row.survey}></AdminView>
				) : (
					<UserView {...row}></UserView>
				);
		},
	};

	return (
		<Form {...props}>
			{({ disabled, values }) => {
				return (
					<div className="p-5 bg-white grid grid-cols-1 md:grid-cols-2 gap-8">
						<div className="flex flex-col gap-4">
							<h2 className="bordered">{t("title.survey_info")}</h2>
							<Field
								name="template"
								label="field.template"
								disabled={disabled}
								component={Select}
								axio="/const/select/survey-template"
								labelField="name"
							/>
							<Field
								name="start"
								label="field.start"
								disabled={disabled}
								component={DatePicker}
							/>
							<Field
								name="end"
								label="field.end"
								disabled={disabled}
								component={DatePicker}
							/>
						</div>
						<div className="flex flex-col gap-4">
							<Field
								name="type"
								label="field.type"
								disabled={disabled}
								component={Tabs}
								axio="/const/static/sigment_type"
								axioAdapter={(data) => {
									return data.map(({ _id }) => ({
										value: _id,
										label: t(`const.sigment_type.${_id}`),
									}));
								}}
							/>
							{values.type === "class" && (
								<Field
									key={values.type}
									name="class"
									isMulti={true}
									label="field.class"
									disabled={disabled}
									component={Select}
									axio="/const/select/class"
									labelField="current"
								/>
							)}
							<Field
								name="roles"
								label="field.role"
								disabled={disabled}
								component={Select}
								isMulti={true}
								items={["student", "parent", "teacher"].map((role) => ({
									value: role,
									label: t(`const.role.${role}`),
								}))}
							/>
							<Field
								name="title"
								label="field.title"
								disabled={disabled}
								component={Text}
								type="textarea"
							/>
						</div>
					</div>
				);
			}}
		</Form>
	);
};

export default SurveyForm;
