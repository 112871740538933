import { useTranslation } from "react-i18next";
import BigTitile from "./BigTitle";

const WORDS = ["0", "1", "2", "3", "4", "5", "6"];
const Intro = () => {
	const { t } = useTranslation();

	return (
		<div className="p-10">
			<BigTitile title={t("label.dir_title")}></BigTitile>
			<div className="container mx-auto flex flex-wrap gap-5 items-center mb-10 text-dark-100 text-justify text-sm">
				<img
					src="/images/dir.png"
					alt="directory"
					className="mx-auto w-full lg:w-96 self-start"
				/>
				<div className="flex-1">
					{WORDS.map((w, index) => (
						<div key={index} className="mb-4">
							{t(`label.dir_greetings.${w}`)}
						</div>
					))}

					<div className="text-secondary-100 text-right mt-5 w-full">
						{t(`label.dir_name`)}
					</div>
				</div>
			</div>
		</div>
	);
};
export default Intro;
