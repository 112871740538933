import {
	RiLineChartLine,
	RiCalendar2Line,
	RiNewspaperLine,
	RiBook2Line,
	RiVideoLine,
	RiSettings2Line,
	RiUser3Fill,
	RiSurveyLine,
	RiFeedbackLine,
	RiVolumeVibrateLine,
	RiTaskLine,
	RiFilePdfFill,
	RiMailCheckLine,
	RiQuestionLine,
	RiSurveyFill,
	RiSearchLine,
} from "react-icons/ri";

const Admin = [
	{ label: "dashboard", path: "/app/dashboard", icon: <RiLineChartLine /> },
	{ label: "class", path: "/app/class", icon: <RiCalendar2Line /> },
	{ label: "task", path: "/app/task", icon: <RiTaskLine /> },
	{ label: "employee", path: "/app/employee", icon: <RiUser3Fill /> },
	{ label: "feedback", path: "/app/feedback", icon: <RiFeedbackLine /> },
	{ label: "poll", path: "/app/poll", icon: <RiQuestionLine /> },
	{
		label: "announcement",
		path: "/app/announcement",
		icon: <RiVolumeVibrateLine />,
	},
	{ label: "enrollment", path: "/app/enrollment", icon: <RiMailCheckLine /> },
	{ label: "survey", path: "/app/survey", icon: <RiSurveyFill /> },
	{
		label: "survey-template",
		path: "/app/survey-template",
		icon: <RiSurveyLine />,
	},
	{ label: "article", path: "/app/article", icon: <RiNewspaperLine /> },
	{ label: "book", path: "/app/book", icon: <RiBook2Line /> },
	{ label: "lesson", path: "/app/lesson", icon: <RiVideoLine /> },
	{ label: "transparent", path: "/app/report", icon: <RiFilePdfFill /> },
	{ label: "search", path: "/app/search", icon: <RiSearchLine /> },
	{ label: "settings", path: "/app/settings", icon: <RiSettings2Line /> },
];

const Teacher = [
	{ label: "dashboard", path: "/app/dashboard", icon: <RiLineChartLine /> },
	{ label: "task", path: "/app/task", icon: <RiTaskLine /> },
	{ label: "poll", path: "/app/poll", icon: <RiQuestionLine /> },
	{
		label: "announcement",
		path: "/app/announcement",
		icon: <RiVolumeVibrateLine />,
	},
	{ label: "survey", path: "/app/survey", icon: <RiSurveyFill /> },
	{ label: "book", path: "/app/book", icon: <RiBook2Line /> },
	{ label: "lesson", path: "/app/lesson", icon: <RiVideoLine /> },
	{ label: "settings", path: "/app/settings", icon: <RiSettings2Line /> },
];

const Parent = [
	{ label: "dashboard", path: "/app/dashboard", icon: <RiLineChartLine /> },
	{ label: "task", path: "/app/task", icon: <RiTaskLine /> },
	{ label: "poll", path: "/app/poll", icon: <RiQuestionLine /> },
	{
		label: "announcement",
		path: "/app/announcement",
		icon: <RiVolumeVibrateLine />,
	},
	{ label: "survey", path: "/app/survey", icon: <RiSurveyFill /> },
	{ label: "book", path: "/app/book", icon: <RiBook2Line /> },
	{ label: "lesson", path: "/app/lesson", icon: <RiVideoLine /> },
	{ label: "settings", path: "/app/settings", icon: <RiSettings2Line /> },
];

const Student = [
	{ label: "dashboard", path: "/app/dashboard", icon: <RiLineChartLine /> },
	{ label: "task", path: "/app/task", icon: <RiTaskLine /> },
	{ label: "poll", path: "/app/poll", icon: <RiQuestionLine /> },
	{
		label: "announcement",
		path: "/app/announcement",
		icon: <RiVolumeVibrateLine />,
	},
	{ label: "survey", path: "/app/survey", icon: <RiSurveyFill /> },
	{ label: "book", path: "/app/book", icon: <RiBook2Line /> },
	{ label: "lesson", path: "/app/lesson", icon: <RiVideoLine /> },
	{ label: "settings", path: "/app/settings", icon: <RiSettings2Line /> },
];

const MENU = {
	admin: Admin,
	teacher: Teacher,
	parent: Parent,
	student: Student,
};

export default MENU;
