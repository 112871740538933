import { useTranslation } from "react-i18next";
import Table from "../../list/Table";
import { more, translateCell } from "../../providers/format";

const FeedbackList = () => {
	const { t } = useTranslation();

	return (
		<Table
			url={"/feedback/table"}
			columns={[
				{
					key: "type",
					label: "field.type",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/static/feedback_type",
						axioAdapter: (data) => {
							return data.map(({ _id }) => ({
								value: _id,
								label: t(`const.feedback_type.${_id}`),
							}));
						},
					},
					render: (row) => translateCell(row, t, "feedback_type", "type"),
				},
				{
					key: "content",
					label: "field.content",
					sortable: true,
					filter: true,
				},
				{
					key: "status",
					label: "field.status",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/static/feedback_status",
						axioAdapter: (data) => {
							return data.map(({ _id }) => ({
								value: _id,
								label: t(`const.feedback_status.${_id}`),
							}));
						},
					},
					render: (row) => translateCell(row, t, "feedback_status", "status"),
				},
				{
					key: "responded",
					label: "field.responded",
					sortable: true,
					filter: { type: "date" },
					render: ({ _responded }) => _responded,
				},
				{
					key: "respond",
					label: "field.respond",
					sortable: true,
					filter: true,
				},
				{
					key: "private",
					label: "field.createdby",
					render: ({ private: _private, createdby }) =>
						_private ? "******* *******" : createdby?.fullname,
				},
				{ render: ({ _id }) => more(`view/${_id}`) },
			]}
		></Table>
	);
};

export default FeedbackList;
