import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiPlus } from "react-icons/hi";
import { RiSearchLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Tabs } from "../../inputs";
import Table from "../../list/Table";
import { useApp } from "../../providers/app";
import { formatMedia, more, translateCell } from "../../providers/format";
import { CreatedBy } from "../../template";

const ClassList = ({ type }) => {
	const { t } = useTranslation();
	const { user } = useApp();
	const [search, setSearch] = useState("");
	const [selectedClass, setSelectedClass] = useState(null);

	useEffect(() => {
		setSearch("");
		setSelectedClass(null);
	}, [type]);

	return (
		<Table
			key={type}
			defaultFind={{
				type,
				...(user?.role !== "admin" ? { published: true } : null),
			}}
			url={"/content/table"}
			renderHeader={
				user?.role !== "admin"
					? null
					: () => {
							return (
								<div className="flex justify-end">
									<div className="mb-4">
										<Link to="new" className="button">
											{t(
												`button.${
													type === "lesson" ? "create_lesson" : "create_book"
												}`
											)}
											<HiPlus className="text-lg ml-1" />
										</Link>
									</div>
								</div>
							);
					  }
			}
			columns={
				user?.role !== "admin"
					? []
					: [
							{
								key: "thumb",
								label: "field.thumb",
								render: (row) => formatMedia(row, "thumb", "title", "w-16"),
							},
							{
								key: "title",
								label: "field.title",
								sortable: true,
								filter: true,
							},
							{
								key: "grades",
								label: "field.grade",
								sortable: true,
								filter: { type: "number" },
								render: ({ grades }) =>
									Array.isArray(grades) && grades.join(", "),
							},
							{
								key: "lesson",
								label: "field.lesson",
								sortable: true,
								filter: {
									type: "select",
									axio: "/const/static/lesson",
									axioAdapter: (data) => {
										return data.map(({ _id }) => ({
											value: _id,
											label: t(`const.lesson.${_id}`),
										}));
									},
								},
								render: (row) => translateCell(row, t, "lesson", "lesson"),
							},
							{
								key: "published",
								label: "field.published",
								render: ({ published }) =>
									published ? "Нийтлэгдсэн" : "Драфт",
							},
							{
								label: "field.createdby",
								width: 250,
								render: ({ created, createdby }) => (
									<CreatedBy date={created} user={createdby}></CreatedBy>
								),
							},
							{ render: ({ _id }) => more(`view/${_id}`) },
					  ]
			}
			containerClassName="grid grid-cols-4 gap-5 p-5"
			renderItem={
				user?.role === "admin"
					? null
					: (row) => {
							return (
								<Link key={row._id} to={`view/${row._id}`} className="pointer">
									<div className="text-center">
										{formatMedia(
											row,
											"thumb",
											"title",
											"h-64 mx-auto object-cover"
										)}
										<div className="text-dark-100 mt-2">{row.title}</div>
									</div>
								</Link>
							);
					  }
			}
		>
			{user?.role === "admin"
				? null
				: ({ filterChange }) => {
						return (
							<div className="mb-5">
								<Tabs
									items={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
										(i) => ({
											value: String(i),
											label: `${i}-${t("field.class")}`,
										})
									)}
									value={selectedClass}
									onChange={(value) => {
										setSelectedClass(value);
										filterChange("grades", value);
									}}
									all
								></Tabs>
								<div className="flex">
									<input
										className="w-64"
										placeholder={t("label.search")}
										value={search}
										onChange={({ target }) => {
											setSearch(target.value);
										}}
									/>
									<button
										className="w-16 ml-2"
										onClick={() => {
											filterChange("title", { $regex: search, $options: "i" });
										}}
									>
										<RiSearchLine></RiSearchLine>
									</button>
								</div>
							</div>
						);
				  }}
		</Table>
	);
};

export default ClassList;
