import { useTranslation } from "react-i18next";
import RecentArticles from "../components/article/Recent";
import BigTitile from "../components/landing/BigTitle";
import { Field, Form, Loader } from "../template";
import * as Yup from "yup";
import { fileValidation, stringValidation } from "../providers/validations";
import { FileUpload, Select, Text } from "../inputs";
import { Formik, Form as FormikForm } from "formik";
import { mainApi } from "../providers/api";
import { successAlert, translateError } from "../providers/alert";
import { RiCheckLine } from "react-icons/ri";

const Join = ({ enrollment }) => {
	const { t } = useTranslation();
	const formik = {
		initialValues: {
			register: "",
			firstname: "",
			lastname: "",
			address: "",
			phone: "",
			email: "",
			gender: "",
			enrollment_parent: "",
			enrollment_passport: [],
			image: [],
			birth: [],
			payment: [],
		},
		validationSchema: Yup.object().shape({
			register: stringValidation(true),
			firstname: stringValidation(true),
			lastname: stringValidation(true),
			address: stringValidation(true),
			phone: stringValidation(true),
			email: stringValidation(true),
			gender: stringValidation(true),
			enrollment_parent: stringValidation(true),
			enrollment_passport: fileValidation(true),
			image: fileValidation(true),
			birth: fileValidation(true),
			payment: fileValidation(true),
		}),
		onSubmit: async (values, { setSubmitting, resetForm }) => {
			try {
				const response = await mainApi({
					url: `/public/enrollment`,
					method: "POST",
					data: values,
				});

				setSubmitting(false);
				successAlert(null, `№: ${response.data}`);
				resetForm();
			} catch (error) {
				translateError(error);
			}
		},
	};

	return (
		<div className="container mx-auto bg-white p-2">
			{enrollment && (
				<BigTitile
					title={t("label.join")}
					subtitle={t("label.join_subtitle")}
				></BigTitile>
			)}
			{enrollment && (
				<Formik {...formik}>
					{({ isSubmitting, errors }) => {
						return (
							<FormikForm className="p-5 bg-white grid grid-cols-1 md:grid-cols-2 gap-8 relative">
								{isSubmitting && <Loader />}
								<div className="flex flex-col gap-4">
									<Field
										name="register"
										label="field.register"
										component={Text}
									/>
									<Field
										name="lastname"
										label="field.lastname"
										component={Text}
									/>
									<Field
										name="firstname"
										label="field.firstname"
										component={Text}
									/>
									<Field
										name="gender"
										label="field.gender"
										component={Select}
										isSearchable={false}
										axio="/const/static/gender"
										axioAdapter={(data) => {
											return data.map(({ _id }) => ({
												value: _id,
												label: t(`const.gender.${_id}`),
											}));
										}}
									/>
									<Field
										name="phone"
										label="field.phone"
										component={Text}
										type="number"
									/>
									<Field name="email" label="field.email" component={Text} />
									<Field
										name="address"
										label="field.address"
										component={Text}
										type="textarea"
										rows={5}
									/>
								</div>
								<div className="flex flex-col gap-4">
									<Field
										name="image"
										label="field.enrollment_photo"
										component={FileUpload}
										root="enrollment"
										limit={5}
									/>
									<Field
										name="birth"
										label="field.birth"
										component={FileUpload}
										root="enrollment"
										limit={5}
									/>
									<Field
										name="payment"
										label="field.payment"
										component={FileUpload}
										root="enrollment"
										limit={5}
									/>
									<Field
										name="enrollment_parent"
										label="field.enrollment_parent"
										component={Text}
										type="textarea"
										rows={5}
									/>
									<Field
										name="enrollment_passport"
										label="field.enrollment_passport"
										component={FileUpload}
										root="enrollment"
										limit={5}
									/>
									<div className="self-end mb-10">
										<button type="submit" className="secondary">
											{t("button.send")}
											<RiCheckLine className="ml-1 text-lg" />
										</button>
									</div>
								</div>
							</FormikForm>
						);
					}}
				</Formik>
			)}
			{!enrollment && (
				<>
					<div className="flex-1 z-10 items-center mb-20">
						<BigTitile
							title={t("label.school_title")}
							subtitle={`Элсэлтийн бүртгэл 2024-08-16 00:00 цагаас нээгдэж 2024-08-22 00:00 цагт хаагдана.`}
						></BigTitile>
						<div className="w-full lg:w-1/2 mx-auto">
							<video src="/video/rus2024.mp4" controls></video>
						</div>
					</div>
					<BigTitile
						title={t("label.exam")}
						subtitle={t("label.exam_subtitle")}
					></BigTitile>
					<iframe
						title={t("label.join")}
						className="w-full h-screen"
						src="/pdf/book.pdf"
					/>
					<RecentArticles></RecentArticles>
				</>
			)}
		</div>
	);
};

export default Join;
