import AppMockup from "../components/landing/AppMockup";
import Intro from "../components/landing/Intro";
import Stats from "../components/landing/Stats";
import Directory from "../components/landing/Directory";

const Home = () => {
	return (
		<div>
			<Directory></Directory>
			<Intro></Intro>
			<Stats></Stats>
			<AppMockup></AppMockup>
		</div>
	);
};

export default Home;
