import { useTranslation } from "react-i18next";
import { HiPlus } from "react-icons/hi";
import { Link } from "react-router-dom";
import Table from "../../list/Table";
import { more, userField } from "../../providers/format";

const ClassList = () => {
	const { t } = useTranslation();

	return (
		<Table
			url={"/class/table"}
			renderHeader={() => {
				return (
					<div className="flex justify-end">
						<div className="mb-4">
							<Link to="new" className="button">
								{t("button.create_class")}
								<HiPlus className="text-lg ml-1" />
							</Link>
						</div>
					</div>
				);
			}}
			columns={[
				{
					key: "year",
					label: "field.year",
					filter: true,
					sortable: true,
				},
				{
					key: "current",
					label: "field.current",
					sortable: true,
					filter: true,
				},
				{
					key: "grade",
					label: "field.grade",
					filter: { type: "number" },
					sortable: true,
				},
				{
					key: "group",
					label: "field.group",
					filter: true,
					sortable: true,
				},
				{
					key: "student_count",
					label: "field.student_count",
					filter: { type: "number" },
					sortable: true,
				},
				{
					key: "teachers",
					label: "field.class_teacher",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/select/teacher",
						labelField: "fullname",
					},
					render: ({ teachers }) =>
						Array.isArray(teachers) && teachers.map(userField),
				},
				{ render: ({ _id }) => more(`view/${_id}`) },
			]}
		></Table>
	);
};

export default ClassList;
