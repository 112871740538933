import { useTranslation } from "react-i18next";
import BigTitile from "../components/landing/BigTitle";
import ContactDetails from "../components/landing/ContactDetails";
import Socials from "../components/landing/Socials";

const Contact = () => {
	const { t } = useTranslation();

	return (
		<div>
			<div className="container mx-auto">
				<BigTitile
					title={t("label.contact_title")}
					subtitle={t("label.contact_subtitle")}
				></BigTitile>
				<img
					src="/images/contact_banner.png"
					className="w-full mb-10"
					alt="banner"
				/>
				<div className="text-center leading-8">
					<ContactDetails />
				</div>
				<h2 className="text-center mt-10">{t("label.follow")}</h2>
				<div className="flex justify-center mb-10">
					<div>
						<Socials />
					</div>
				</div>
			</div>
			<iframe
				title="School direction"
				src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2674.3088652609795!2d106.9012685159921!3d47.91106087920602!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5d9692523a7fd8ed%3A0x32e301d5d02d8fdf!2sMongolian-Russian%20Joint%20School!5e0!3m2!1sen!2smn!4v1676490107760!5m2!1sen!2smn"
				width="100%"
				height="500"
				loading="lazy"
				referrerPolicy="no-referrer-when-downgrade"
			></iframe>
		</div>
	);
};

export default Contact;
