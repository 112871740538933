import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { Field, Form } from "../../template";
import { stringValidation } from "../../providers/validations";
import { Select, Text, Toggle } from "../../inputs";
import { useApp } from "../../providers/app";
import { useTranslation } from "react-i18next";

const ParentForm = ({ user_id, profile }) => {
	const { id } = useParams();
	const { user } = useApp();
	const { t } = useTranslation();
	const { student_id } = useParams();

	const supervisor = ["admin", "teacher"].includes(user.role);

	const props = {
		editable: supervisor || !!profile,
		isDeletable: supervisor && !profile,
		model: "user",
		id: id || user_id,
		init: {
			active: true,
			firstname: "",
			lastname: "",
			phone: "",
			relation: "",
			student_id,
			role: "parent",
		},

		validationSchema: Yup.object().shape({
			relation: stringValidation(!(id || user_id)),
			firstname: stringValidation(true),
			phone: stringValidation(true),
		}),
		success: !profile
			? null
			: () => {
					window.location.reload();
			  },
	};

	return (
		<div>
			<Form {...props}>
				{({ disabled }) => {
					return (
						<div className="p-5 bg-white grid grid-cols-1 md:grid-cols-2 gap-8">
							<div className="flex flex-col gap-4">
								<h2 className="bordered">{t("title.parent_info")}</h2>
								{(id || profile) && (
									<div className="flex flex-col gap-4 p-5 bg-dark-5">
										{!profile && (
											<Field
												name="active"
												label="field.active"
												disabled={profile || disabled}
												component={Toggle}
											/>
										)}
										<Field
											name="code"
											label="field.code"
											disabled={true}
											component={Text}
										/>
										<Field
											name="password"
											label="field.password"
											type="password"
											disabled={disabled}
											component={Text}
										/>
									</div>
								)}
								{!(id || user_id) && (
									<Field
										name={`relation`}
										label="field.relation"
										disabled={disabled}
										component={Select}
										axio="/const/static/relation"
										axioAdapter={(data) => {
											return data.map(({ _id }) => ({
												value: _id,
												label: t(`const.relation.${_id}`),
											}));
										}}
									/>
								)}
								<Field
									name="lastname"
									label="field.lastname"
									disabled={profile || disabled}
									component={Text}
								/>
								<Field
									name="firstname"
									label="field.firstname"
									disabled={profile || disabled}
									component={Text}
								/>
								<Field
									name="phone"
									label="field.phone"
									disabled={disabled}
									component={Text}
								/>
							</div>
						</div>
					);
				}}
			</Form>
		</div>
	);
};

export default ParentForm;
