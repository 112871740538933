import { useTranslation } from "react-i18next";
import { HiPlus } from "react-icons/hi";
import { Link } from "react-router-dom";
import Table from "../../list/Table";
import { useApp } from "../../providers/app";
import { more, translateCell } from "../../providers/format";
import { CreatedBy } from "../../template";

const defaultFind = ({ role, ...user }) => {
	if (role === "admin") return null;
	if (role === "teacher")
		return { $or: [{ createdby: user.id }, { type: "school" }] };
	if (role === "student") return { class: { $in: [null, user.class?.id] } };
	if (role === "parent" && Array.isArray(user.children))
		return {
			class: {
				$in: [
					null,
					...user.children.map((child) => child?.student?.class?._id),
				],
			},
		};
	return { _id: null };
};

const AnnouncementList = () => {
	const { t } = useTranslation();
	const { user } = useApp();
	const supervisor = ["admin", "teacher"].includes(user.role);

	return (
		<Table
			url={"/announcement/table"}
			defaultFind={defaultFind(user)}
			renderHeader={
				["admin", "teacher"].includes(user.role)
					? () => {
							return (
								<div className="flex justify-end">
									<div className="mb-4">
										<Link to="new" className="button">
											{t("button.create_announcement")}
											<HiPlus className="text-lg ml-1" />
										</Link>
									</div>
								</div>
							);
					  }
					: null
			}
			columns={[
				{
					key: "type",
					label: "field.type",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/static/sigment_type",
						axioAdapter: (data) => {
							return data.map(({ _id }) => ({
								value: _id,
								label: t(`const.sigment_type.${_id}`),
							}));
						},
					},
					render: (row) => translateCell(row, t, "sigment_type", "type"),
				},
				supervisor && {
					key: "class",
					label: "field.class",
					filter: {
						type: "select",
						axio: "/const/select/class",
						labelField: "current",
					},
					render: ({ class: _class }) => _class?.current,
				},
				{
					key: "content",
					label: "field.content",
					sortable: true,
					filter: true,
				},
				{
					label: "field.createdby",
					render: ({ created, createdby }) => (
						<CreatedBy date={created} user={createdby}></CreatedBy>
					),
				},
				supervisor && { render: ({ _id }) => more(`view/${_id}`) },
			]}
		></Table>
	);
};

export default AnnouncementList;
