import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BigTitile from "../components/landing/BigTitle";
import axios from "axios";
import { Article } from "../components/article/Recent";

const Articles = () => {
	const { t } = useTranslation();
	const [articles, setArticles] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			const response = await axios({
				url: `public/article`,
				method: "POST",
				data: { find: {}, sort: { created: -1 }, limit: 48 },
			});
			setArticles(response.data);
		};

		fetchData();
	}, []);

	return (
		<div className="container mx-auto p-2">
			<BigTitile
				title={t("label.articles")}
				subtitle={t("label.featured_subtitle")}
			></BigTitile>
			<div className="grid grid-cols-1 md:grid-cols-3 gap-5 mb-10">
				{articles.map((item) => (
					<Article key={item.id} {...item} />
				))}
			</div>
		</div>
	);
};

export default Articles;
