import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getAbsolutePath, getTranslated } from "../../providers/format";

const ReportCard = (props) => {
	const { t, i18n } = useTranslation();
	const { _date, thumb, content } = props;

	const img = getAbsolutePath(thumb);
	const src = getAbsolutePath(content);
	const title = getTranslated(i18n.language, props, "title");

	return (
		<Link
			target="_blank"
			to={`${src}`}
			className="relative rounded-lg overflow-hidden pointer"
		>
			<img
				alt={title}
				src={img}
				crossOrigin="anonymous"
				className="h-full w-full object-cover rounded-xl"
			/>
			<div className="overlay absolute top-0 bottom-0 right-0 left-0 flex flex-col justify-end items-start p-2 text-white text-left leading-5">
				<div className="link">{title}</div>
				<div className="text-xs self-end opacity-60 mt-2">
					{t("label.published")}: {_date}
				</div>
			</div>
		</Link>
	);
};

export default ReportCard;
