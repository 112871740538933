import { useTranslation } from "react-i18next";
import { HiPlus } from "react-icons/hi";
import { Link } from "react-router-dom";
import Table from "../../list/Table";
import { formatMedia, more, translateCell } from "../../providers/format";
import { CreatedBy } from "../../template";

const ArticleList = () => {
	const { t } = useTranslation();

	return (
		<Table
			url={"/article/table"}
			renderHeader={() => {
				return (
					<div className="flex justify-end">
						<div className="mb-4">
							<Link to="new" className="button">
								{t("button.create_article")}
								<HiPlus className="text-lg ml-1" />
							</Link>
						</div>
					</div>
				);
			}}
			columns={[
				{
					key: "thumb",
					label: "field.thumb",
					render: (row) => formatMedia(row, "thumb", "title", "w-24"),
				},
				{
					key: "type",
					label: "field.type",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/static/article_type",
						axioAdapter: (data) => {
							return data.map(({ _id }) => ({
								value: _id,
								label: t(`const.article_type.${_id}`),
							}));
						},
					},
					render: (row) => translateCell(row, t, "article_type", "type"),
				},
				{
					key: "title",
					label: "field.title",
					sortable: true,
					filter: true,
				},
				{
					key: "published",
					label: "field.published",
					render: ({ published }) => (published ? "Нийтлэгдсэн" : "Драфт"),
				},
				{
					label: "field.createdby",
					width: 250,
					render: ({ created, createdby }) => (
						<CreatedBy date={created} user={createdby}></CreatedBy>
					),
				},
				{ render: ({ _id }) => more(`view/${_id}`) },
			]}
		></Table>
	);
};

export default ArticleList;
