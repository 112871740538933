import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { getAbsolutePath, getTranslated } from "../../providers/format";

const TYPE_COLORS = {
	news: "#ED1E35",
	announcement: "#2379BE",
	event: "#FFD43E",
};

export const Article = ({
	thumb,
	type,
	_created,
	id,
	height = 215,
	trunc,
	...article
}) => {
	const { t, i18n } = useTranslation();
	const img = getAbsolutePath(thumb);
	const content = getTranslated(i18n.language, article, "content");
	const title = getTranslated(i18n.language, article, "title");

	const words = (content || "").split(" ").slice(0, 60);

	return (
		<div className="text-dark-100 bg-white rounded-xl">
			<img
				alt={title}
				src={img}
				crossOrigin="anonymous"
				className="w-full object-cover rounded-t-xl"
				style={{ height }}
			/>
			<div className="flex flex-col justify-end items-start p-5 text-left leading-5">
				<div className="mb-2 flex items-center">
					<div
						className="w-3 h-3 mr-1 rounded-full"
						style={{ background: TYPE_COLORS[type] }}
					></div>
					<div className="text-xs opacity-60">
						{t(`const.article_type.${type}`)}
					</div>
				</div>
				<Link
					to={`/public/article/${id}`}
					className={`link ${trunc ? "text-lg" : "text-sm"}`}
				>
					{title}
				</Link>
				{trunc && (
					<div className="text-dark-60 text-sm">{words.join(" ")}...</div>
				)}
				<div className="text-xs self-end opacity-60 mt-5">
					{t("label.published")}: {_created}
				</div>
			</div>
		</div>
	);
};

const RecentArticles = () => {
	const [articles, setArticles] = useState([]);
	const [featured, setFeatured] = useState();

	useEffect(() => {
		const fetchData = async () => {
			const response = await axios({
				url: `public/article`,
				method: "POST",
				data: { find: {}, sort: { created: -1 }, limit: 6 },
			});
			const [f, ...items] = response.data;

			setFeatured(f);
			setArticles(items);
		};

		fetchData();
	}, []);

	return (
		<div className="p-5 relative">
			<div className="container mx-auto">
				<div className="block lg:grid grid-cols-1 lg:grid-cols-3 gap-5 justify-around text-center text-secondary-100">
					<div className={"col-span-2 mb-5"}>
						<Article {...featured} height={470} trunc />
					</div>
					<div
						className={"hidden flex-col gap-5 overflow-y-scroll lg:flex"}
						style={{ height: 700 }}
					>
						{articles.map((article) => (
							<Article key={article._id} {...article} />
						))}
					</div>
					<div className={"flex flex-col gap-5 lg:hidden"}>
						{articles.map((article) => (
							<Article key={article._id} {...article} />
						))}
					</div>
				</div>
			</div>
		</div>
	);
};
export default RecentArticles;
