import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BigTitile from "../components/landing/BigTitle";
import ReportCard from "../components/report/Card";
import axios from "axios";
import { Link } from "react-router-dom";
import { RiArrowRightLine } from "react-icons/ri";

const Report = () => {
	const { t } = useTranslation();
	const [reports, setReport] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			const response = await axios({
				url: `public/report`,
				method: "POST",
				data: { find: {}, sort: { created: -1 }, limit: 48 },
			});
			setReport(response.data);
		};

		fetchData();
	}, []);

	return (
		<div className="container mx-auto bg-white p-2">
			<BigTitile title={t("menu.transparent")}></BigTitile>
			<div className="grid grid-cols-1 md:grid-cols-3 gap-5 mb-10">
				{reports.map((item) => (
					<ReportCard key={item.id} {...item}></ReportCard>
				))}
			</div>

			<div className="text-center text-dark-100">{t("label.shilendans")}</div>
			<div className="flex justify-center my-10">
				<Link
					target="_blank"
					to="https://shilendans.gov.mn/organization/18928"
					className="link-button"
				>
					{t("button.more")}
					<RiArrowRightLine className="ml-2" />
				</Link>
			</div>
		</div>
	);
};

export default Report;
