import { useTranslation } from "react-i18next";
import { getTranslated } from "../../providers/format";
import { getImageSrc } from "../../providers/utils";

const EmployeeCard = ({ profile, ...props }) => {
	const { i18n } = useTranslation();
	const img = getImageSrc(profile);

	const name = getTranslated(i18n.language, props, "name");
	const brief = getTranslated(i18n.language, props, "brief");

	return (
		<div className="rounded-xl grid justify-center">
			<img
				crossOrigin="anonymous"
				alt={name}
				src={img}
				style={{ height: 390 }}
				className="object-cover rounded-xl mx-auto"
			/>
			<div className="py-4 text-center">
				<div>
					<span className="uppercase">{name}</span>
				</div>
				<div className="text-sm text-dark-80">{brief}</div>
			</div>
		</div>
	);
};

export default EmployeeCard;
