import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { Field, Form } from "../../template";
import { stringValidation } from "../../providers/validations";
import { Select, Tabs, Text } from "../../inputs";
import { useApp } from "../../providers/app";
import { useTranslation } from "react-i18next";

const AnnouncementForm = () => {
	const { id } = useParams();
	const { user } = useApp();
	const { t } = useTranslation();

	const props = {
		editable: user.role === "admin",
		isDeletable: user.role === "admin",
		model: "announcement",
		id,
		init: {
			content: "",
			type: "",
			class: null,
		},
		validationSchema: Yup.object().shape({
			content: stringValidation(true),
			type: stringValidation(true),
		}),
	};

	return (
		<Form {...props}>
			{({ disabled, values }) => {
				return (
					<div className="p-5 bg-white grid grid-cols-1 md:grid-cols-2 gap-8">
						<div className="flex flex-col gap-4">
							<h2 className="bordered">{t("title.announcement_info")}</h2>
							<Field
								name="type"
								label="field.type"
								disabled={disabled}
								component={Tabs}
								axio="/const/static/sigment_type"
								axioAdapter={(data) => {
									return data.map(({ _id }) => ({
										value: _id,
										label: t(`const.sigment_type.${_id}`),
									}));
								}}
							/>
							{values.type === "class" && (
								<Field
									key={values.type}
									name="class"
									label="field.class"
									disabled={disabled}
									component={Select}
									axio="/const/select/class"
									labelField="current"
								/>
							)}
							<Field
								name="content"
								label="field.content"
								disabled={disabled}
								component={Text}
								type="textarea"
							/>
						</div>
					</div>
				);
			}}
		</Form>
	);
};

export default AnnouncementForm;
