import { useTranslation } from "react-i18next";
import { HiPlus } from "react-icons/hi";
import { Link } from "react-router-dom";
import Table from "../../list/Table";
import { useApp } from "../../providers/app";
import { formatDate, more, translateCell } from "../../providers/format";
import { CreatedBy } from "../../template";

const defaultFind = ({ role, ...user }) => {
	if (role === "admin") return null;
	if (role === "teacher") return { $or: [{ createdby: user.id }] };
	if (role === "student") return { class: user.class?.id };
	if (role === "parent" && Array.isArray(user.children))
		return {
			class: {
				$in: user.children.map((child) => child?.student?.class?._id),
			},
		};
	return { _id: null };
};

const TaskList = () => {
	const { t } = useTranslation();
	const { user } = useApp();

	return (
		<Table
			url={"/task/table"}
			defaultFind={defaultFind(user)}
			renderHeader={
				["teacher"].includes(user.role)
					? () => {
							return (
								<div className="flex justify-end">
									<div className="mb-4">
										<Link to="new" className="button">
											{t("button.create_task")}
											<HiPlus className="text-lg ml-1" />
										</Link>
									</div>
								</div>
							);
					  }
					: null
			}
			columns={[
				{
					key: "class",
					label: "field.class",
					filter: {
						type: "select",
						axio: "/const/select/class",
						labelField: "current",
					},
					render: ({ class: _class }) => {
						if (Array.isArray(_class))
							return _class.map((c) => c.current).join(", ");
						return _class?.current;
					},
				},
				{
					key: "lesson",
					label: "field.lesson",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/static/lesson",
						axioAdapter: (data) => {
							return data.map(({ _id }) => ({
								value: _id,
								label: t(`const.lesson.${_id}`),
							}));
						},
					},
					render: (row) => translateCell(row, t, "lesson", "lesson"),
				},
				{
					key: "date",
					label: "field.date",
					sortable: true,
					width: 200,
					filter: { type: "date" },
					render: (row) => formatDate(row, "date", "yyyy-MM-dd"),
				},
				{
					key: "description",
					label: "menu.task",
					sortable: true,
					width: 400,
					filter: true,
				},
				{
					label: "field.createdby",
					render: ({ created, teacher }) => (
						<CreatedBy date={created} user={teacher}></CreatedBy>
					),
				},
				{ render: ({ _id }) => more(`view/${_id}`) },
			]}
		></Table>
	);
};

export default TaskList;
