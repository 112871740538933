import { useApp } from "../providers/app";
import AdminDashboard from "./AdminDashboard";
import TeacherDashboard from "./TeacherDashboard";
import StudentDashboard from "./StudentDashboard";
import ParentDashboard from "./ParentDashboard";

const DASHBOARD = {
	admin: <AdminDashboard />,
	teacher: <TeacherDashboard />,
	student: <StudentDashboard />,
	parent: <ParentDashboard />,
};

const Dashboard = () => {
	const { user } = useApp();

	return DASHBOARD[user.role];
};

export default Dashboard;
