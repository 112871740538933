import { useState } from "react";
import ClassForm from "../components/class/Form";
import { Tabs } from "../inputs";
import { useApp } from "../providers/app";

const ParentDashboard = () => {
	const { user } = useApp();

	const { children = [] } = user;
	const items = children.map((c) => ({
		value: c?.student?.class?._id,
		label: `${c?.student?.class?.current} ${c?.firstname?.toUpperCase()}`,
	}));
	const [selected, setSelected] = useState(items[0]?.value);

	if (!selected) return null;

	return (
		<div className="w-full">
			<ClassForm
				id={selected}
				back={
					<Tabs items={items} value={selected} onChange={setSelected}></Tabs>
				}
				hideStudent={true}
			></ClassForm>
		</div>
	);
};

export default ParentDashboard;
