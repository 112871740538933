import { useTranslation } from "react-i18next";

const Stats = () => {
	const { t } = useTranslation();
	return (
		<div className="p-10 bg-secondary-100 relative">
			<div className="container mx-auto">
				<img
					className="absolute top-0 left-0"
					alt="pattern"
					src="/images/zigzag.png"
				/>
				<img
					className="absolute bottom-0 right-0"
					alt="pattern"
					src="/images/dots.png"
				/>
				<div className="grid grid-cols-1 sm:grid-cols-3 gap-5 justify-around text-center text-secondary-100">
					<div>
						<div className="text-6xl font-bold stroke">3000+</div>
						<div className="text-white opacity-60 w-32 mx-auto">
							{t("label.student_count")}
						</div>
					</div>
					<div>
						<div className="text-6xl font-bold stroke">200+</div>
						<div className="text-white opacity-60 w-32 mx-auto">
							{t("label.emp_count")}
						</div>
					</div>
					<div>
						<div className="text-6xl font-bold stroke">40-</div>
						<div className="text-white opacity-60 w-32 mx-auto">
							{t("label.cap_count")}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Stats;
