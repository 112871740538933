import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import Teacher from "./Teacher";
import { DAYS } from "../../defines";
import Schedule from "./Schedule";
import StudentList from "../user/StudentList";

const ClassView = ({
	_id,
	year,
	current,
	teachers = [],
	student_count = 0,
	schedule,
	advanced_lessons = [],
	hideStudent,
}) => {
	const { t } = useTranslation();

	const today = format(new Date(), "E");

	return (
		<div className="p-5 bg-white">
			<h2 className="bordered">{t("title.class_info")}</h2>
			<div className="flex gap-10 justify-between p-5 bg-dark-5 rounded mt-4">
				<div className="flex-grow-0">
					<div>
						<span className="text-dark-100 font-normal">
							{t("field.year")}:
						</span>{" "}
						{year}
					</div>
					<div>
						<span className="text-dark-100 font-normal">
							{t("field.class")}:
						</span>{" "}
						{current}
					</div>
					<div>
						<span className="text-dark-100 font-normal">
							{t("field.advanced_lessons")}:
						</span>{" "}
						{advanced_lessons
							.map((lesson) => t(`const.lesson.${lesson}`))
							.join(", ")}
					</div>
					<div>
						<span className="text-dark-100 font-normal">
							{t("field.student_count")}:
						</span>{" "}
						{student_count}
					</div>
				</div>
				<div className="border-l px-4">
					<div className="flex flex-wrap gap-4">
						{teachers.map((teacher) => (
							<Teacher key={teacher.id} {...teacher} />
						))}
					</div>
				</div>
			</div>
			<h2 className="bordered mt-10 mb-4">{t("field.schedule")}</h2>
			<div className="flex flex-1 flex-wrap gap-4">
				{DAYS.map((day) => {
					return (
						<div key={day} className="flex-1">
							<h2 className="uppercase">{t(`field.${day}`)}</h2>
							<div
								className={`mb-10 p-5 ${
									today.toUpperCase() === day.toUpperCase()
										? "bg-dark-5 border-secondary-100 rounded border"
										: ""
								}`}
							>
								{schedule &&
									Array.isArray(schedule[day]) &&
									schedule[day].map(({ lesson, teacher, _id }, index) => {
										return (
											<Schedule
												key={_id}
												lesson={lesson}
												teacher={teacher}
												day={day}
												index={index}
											/>
										);
									})}
							</div>
						</div>
					);
				})}
			</div>
			{!hideStudent && (
				<>
					<h2 className="bordered mb-4">{t("field.student")}</h2>
					<StudentList creatable={true} class_id={_id} />
				</>
			)}
		</div>
	);
};

export default ClassView;
