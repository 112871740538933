import { useTranslation } from "react-i18next";
import { HiPlus } from "react-icons/hi";
import { Link } from "react-router-dom";
import Table from "../../list/Table";
import { formatDate, formatMedia, more } from "../../providers/format";
import { CreatedBy } from "../../template";

const ReportList = () => {
	const { t } = useTranslation();

	return (
		<Table
			url={"/report/table"}
			renderHeader={() => {
				return (
					<div className="flex justify-end">
						<div className="mb-4">
							<Link to="new" className="button">
								{t("button.create_article")}
								<HiPlus className="text-lg ml-1" />
							</Link>
						</div>
					</div>
				);
			}}
			columns={[
				{
					key: "date",
					label: "field.date",
					sortable: true,
					filter: { type: "date" },
					render: (row) => formatDate(row, "date", "yyyy-MM-dd HH:mm:ss"),
				},
				{
					key: "title",
					label: "field.title",
					sortable: true,
					filter: true,
				},
				{
					key: "thumb",
					label: "field.thumb",
					render: (row) => formatMedia(row, "thumb", "title", "w-24"),
				},
				{
					key: "published",
					label: "field.published",
					render: ({ published }) => (published ? "Нийтлэгдсэн" : "Драфт"),
				},
				{
					label: "field.createdby",
					width: 250,
					render: ({ created, createdby }) => (
						<CreatedBy date={created} user={createdby}></CreatedBy>
					),
				},
				{ render: ({ _id }) => more(`view/${_id}`) },
			]}
		></Table>
	);
};

export default ReportList;
