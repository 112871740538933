import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { mainApi } from "../../providers/api";
import { successAlert, translateError } from "../../providers/alert";
import { getAbsolutePath, more } from "../../providers/format";

const Vote = ({ id, options, _start, _end }) => {
	const { t } = useTranslation();
	const [selected, setSelected] = useState(null);
	const [response, setResponse] = useState(null);
	const [responded, setResponded] = useState(null);
	const [comment, setComment] = useState();

	useEffect(() => {
		const check = async () => {
			const res = await mainApi({
				method: "GET",
				url: `/poll/${id}/vote/check`,
			});

			if (res.data) {
				setResponded(res.data._created);
				setResponse(+res.data.response);
				setComment(res.data.comment);
			}
		};
		if (id) {
			check();
		}
	}, [id]);

	const sendVote = async () => {
		try {
			if (selected === null) throw new Error("Хариулт сонгоно уу");

			await mainApi({
				method: "PUT",
				url: `/poll/${id}/vote/${selected}`,
				data: { comment },
			});
			const confirm = await successAlert();

			if (confirm) window.location.reload();
		} catch (error) {
			translateError(error);
		}
	};

	return (
		<div>
			<h1 className="mb-4 text-dark-100">{t("label.vote")}</h1>
			<div className="text-sm">
				{t("field.start")}: {_start}
			</div>
			<div className="text-sm">
				{t("field.end")}: {_end}
			</div>
			{responded && (
				<div className="text-sm mt-4">
					{t("field.responded")}: {responded}
				</div>
			)}
			{responded ? (
				<div className="text-sm">
					{t("field.respond")}: {options[response]?.text}
					<br />
					{t("field.comment")}: {comment}
				</div>
			) : (
				<div className="my-4 flex flex-wrap gap-5">
					{options.map(({ text, thumb, attachment }, index) => {
						const imgSrc = getAbsolutePath(thumb, { placeholder: null });
						const pdfSrc = getAbsolutePath(attachment, { placeholder: null });

						return (
							<div className="flex-1 border p-2" style={{ minWidth: 300 }}>
								{imgSrc && (
									<img
										alt={text}
										src={imgSrc}
										className="h-64 object-contain mx-auto mb-2"
										crossOrigin="annonymous"
									/>
								)}
								{pdfSrc && (
									<a
										href={pdfSrc}
										target="_blank"
										className="underline text-dark-100 text-center mb-5 block"
									>
										{t("button.more")}
									</a>
								)}
								<div key={index} className="flex justify-center items-start">
									<input
										disabled={responded}
										id={text}
										name="vote"
										type="radio"
										value={index}
										onClick={() => {
											setSelected(index);
										}}
										className="w-8 cursor-pointer m-2"
									/>
									<label
										className={"select-none whitespace-pre-wrap"}
										htmlFor={text}
									>
										{text}
									</label>
								</div>
							</div>
						);
					})}
					<textarea
						placeholder={t("field.comment")}
						value={comment}
						onChange={(e) => setComment(e.target.value)}
					></textarea>
				</div>
			)}
			{!responded && (
				<div className="flex">
					<div>
						<button
							type="button"
							className="secondary"
							onClick={() => {
								sendVote();
							}}
						>
							{t("button.send")}
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

export default Vote;
