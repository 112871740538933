import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { Field, Form } from "../../template";
import { stringValidation } from "../../providers/validations";
import { FileUpload, Select, Tabs, Text, Toggle } from "../../inputs";
import { useApp } from "../../providers/app";
import { useTranslation } from "react-i18next";

const EmployeeForm = ({ user_id, profile }) => {
	const { id } = useParams();
	const { user } = useApp();
	const { t } = useTranslation();
	const supervisor = user.role === "admin";

	const props = {
		editable: supervisor || !!profile,
		isDeletable: supervisor && !profile,
		model: "user",
		id: user_id || id,
		init: {
			firstname: "",
			lastname: "",
			phone: "",
			profile: [],
			teacher: {
				position: "",
				department: "",
				origin: "",
				lessons: [],
			},
		},

		validationSchema: Yup.object().shape({
			firstname: stringValidation(true),
			phone: stringValidation(true),
		}),
	};

	return (
		<Form {...props}>
			{({ disabled, submited, values }) => {
				return (
					<div className="p-5 bg-white grid grid-cols-1 md:grid-cols-2 gap-8">
						<div className="flex flex-col gap-4">
							<h2 className="bordered">{t("title.employee_info")}</h2>
							{(id || profile) && (
								<div className="flex flex-col gap-4 p-5 bg-dark-5">
									{!profile && (
										<Field
											name="active"
											label="field.active"
											disabled={profile || disabled}
											component={Toggle}
										/>
									)}
									<Field
										name="code"
										label="field.code"
										disabled={true}
										component={Text}
									/>
									<Field
										name="password"
										label="field.password"
										type="password"
										disabled={disabled}
										component={Text}
									/>
								</div>
							)}
							<Field
								name="lastname"
								label="field.lastname"
								disabled={profile || disabled}
								component={Text}
							/>
							<Field
								name="firstname"
								label="field.firstname"
								disabled={profile || disabled}
								component={Text}
							/>
							<Field
								name="phone"
								label="field.phone"
								disabled={disabled}
								component={Text}
							/>
							{!profile && (
								<>
									<Field
										name="role"
										label="field.role"
										disabled={disabled}
										component={Tabs}
										axio="/const/static/user_type"
										axioAdapter={(data) => {
											return data.map(({ _id }) => ({
												value: _id,
												label: t(`const.user_type.${_id}`),
											}));
										}}
									/>
									<Field
										name="teacher.department"
										label="field.department"
										disabled={disabled}
										component={Select}
										axio="/const/static/department"
										axioAdapter={(data) => {
											return data.map(({ _id }) => ({
												value: _id,
												label: t(`const.department.${_id}`),
											}));
										}}
									/>
									<Field
										name="teacher.origin"
										label="field.origin"
										disabled={disabled}
										component={Select}
										axio="/const/static/origin"
										axioAdapter={(data) => {
											return data.map(({ _id }) => ({
												value: _id,
												label: t(`const.origin.${_id}`),
											}));
										}}
									/>
									<Field
										name="teacher.lessons"
										label="field.lesson"
										disabled={disabled}
										component={Select}
										isMulti={true}
										axio="/const/static/lesson"
										axioAdapter={(data) => {
											return data.map(({ _id }) => ({
												value: _id,
												label: t(`const.lesson.${_id}`),
											}));
										}}
									/>
									<Field
										name="teacher.position"
										label="field.position"
										disabled={disabled}
										component={Text}
									/>
									<Field
										name="featured"
										label="field.featured"
										disabled={disabled}
										component={Toggle}
									/>
									{values.featured && (
										<div className="flex flex-col gap-4 p-5 bg-dark-5">
											<Field
												name="featured_category"
												label="field.category"
												disabled={disabled}
												component={Select}
												axio="/const/static/featured_teacher"
												axioAdapter={(data) => {
													return data.map(({ _id }) => ({
														value: _id,
														label: t(`const.featured_teacher.${_id}`),
													}));
												}}
											/>
											<Field
												name="priority"
												label="field.priority"
												disabled={disabled}
												component={Text}
											/>
											<Field
												name="name"
												label="field.name"
												disabled={disabled}
												component={Text}
											/>
											<Field
												name="trans.ru.name"
												label="field.name_ru"
												disabled={disabled}
												component={Text}
											/>
											<Field
												name="trans.us.name"
												label="field.name_us"
												disabled={disabled}
												component={Text}
											/>
											<Field
												name="brief"
												label="field.brief"
												disabled={disabled}
												component={Text}
											/>
											<Field
												name="trans.ru.brief"
												label="field.brief_ru"
												disabled={disabled}
												component={Text}
											/>
											<Field
												name="trans.us.brief"
												label="field.brief_us"
												disabled={disabled}
												component={Text}
											/>
										</div>
									)}
								</>
							)}
						</div>
						<div className="flex flex-col gap-4">
							<Field
								name="profile"
								label="field.profile"
								disabled={profile || disabled}
								submited={submited}
								component={FileUpload}
								root="profile"
							/>
						</div>
					</div>
				);
			}}
		</Form>
	);
};

export default EmployeeForm;
