import { getImageSrc } from "../../providers/utils";

const EmployeeCard = ({ firstname, lastname, position, profile, phone }) => {
	const img = getImageSrc(profile);

	return (
		<div className="rounded-xl grid justify-center">
			<img
				crossOrigin="anonymous"
				alt={position}
				src={img}
				style={{ height: 120 }}
				className="object-cover rounded-xl mx-auto"
			/>
			<div className="py-4 text-center">
				<div>
					<span className="">{lastname}</span>{" "}
					<span className="uppercase">{firstname}</span>
				</div>
				<div className="text-sm text-dark-80">{phone}</div>
			</div>
		</div>
	);
};

export default EmployeeCard;
