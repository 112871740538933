import ClassForm from "../components/class/Form";
import { useApp } from "../providers/app";

const StudentDashboard = () => {
	const { user } = useApp();
	return (
		<div className="w-full">
			<ClassForm
				id={user.class._id}
				back={<div></div>}
				hideStudent={true}
			></ClassForm>
		</div>
	);
};

export default StudentDashboard;
