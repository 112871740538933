import { useTranslation } from "react-i18next";
import RecentArticles from "../components/article/Recent";
import Stats from "../components/landing/Stats";
import FeaturedEmployees from "../components/employee/Featured";
import BigTitile from "../components/landing/BigTitle";

const Home = () => {
	const { t } = useTranslation();

	return (
		<div>
			<BigTitile title={t("menu.campus")}></BigTitile>
			<img src="/images/contact_banner.png" className="w-full" alt="banner" />
			<FeaturedEmployees></FeaturedEmployees>
			<Stats></Stats>
			<RecentArticles></RecentArticles>
		</div>
	);
};

export default Home;
