import { useTranslation } from "react-i18next";
import { FaGlobe } from "react-icons/fa";

export const locales = ["mn", "ru", "en"];

const LanguageSwitch = () => {
	const { i18n } = useTranslation();
	const { language, changeLanguage } = i18n;

	return (
		<div className="flex items-center capitalize select-none gap-2 text-secondary-100">
			<FaGlobe />
			{locales.map((locale, index) => (
				<div
					key={locale}
					className={`${language === locale ? "" : "text-dark-20"} ${
						index === 0 ? "" : "border-l-2 pl-2"
					}`}
				>
					<div
						className="pointer"
						onClick={() => {
							changeLanguage(locale);
						}}
					>
						{locale}
					</div>
				</div>
			))}
		</div>
	);
};

export default LanguageSwitch;
