import { useTranslation } from "react-i18next";
import AppMockup from "../components/landing/AppMockup";
import Intro from "../components/landing/Intro";
import RecentArticles from "../components/article/Recent";
import Stats from "../components/landing/Stats";
import FeaturedEmployees from "../components/employee/Featured";
import Directory from "../components/landing/Directory";
import EnrollmentBanner from "../components/enrollment/Banner";
import BigTitile from "../components/landing/BigTitle";

const Home = () => {
	const { t } = useTranslation();

	return (
		<div>
			<RecentArticles></RecentArticles>
			{/* <Stats></Stats> */}
			<EnrollmentBanner></EnrollmentBanner>
			<FeaturedEmployees></FeaturedEmployees>
			<Intro></Intro>
			<div className="bg-white p-10">
				<div className="container mx-auto ">
					<BigTitile
						title={t("label.togtool")}
						subtitle={t("label.togtool_sub")}
					></BigTitile>
					<iframe
						title={t("label.togtool")}
						className="w-full h-screen"
						src="/pdf/togtool.pdf"
					/>
				</div>
			</div>
			<Directory></Directory>
			<AppMockup></AppMockup>
		</div>
	);
};

export default Home;
