import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { Field, FieldArray, Form } from "../../template";
import { stringValidation } from "../../providers/validations";
import { Select, Text, Toggle } from "../../inputs";
import { useApp } from "../../providers/app";
import { useTranslation } from "react-i18next";
import StudentList from "./StudentList";
import ParentList from "./ParentList";

const StudentForm = ({ student_id, hideStudent, profile }) => {
	const { id } = useParams();
	const { user } = useApp();
	const { t } = useTranslation();
	const { class_id } = useParams();

	const supervisor = ["admin", "teacher"].includes(user.role);

	const props = {
		editable: supervisor || !!profile,
		isDeletable: supervisor && !profile,
		model: "user",
		id: id || student_id,
		init: {
			active: true,
			firstname: "",
			lastname: "",
			phone: "",
			register: "",
			address: "",
			gender: "",
			student: {
				class: class_id,
			},
			role: "student",
		},

		validationSchema: Yup.object().shape({
			firstname: stringValidation(true),
			phone: stringValidation(true),
		}),
		success: id
			? null
			: () => {
					window.location.reload();
			  },
	};

	return (
		<div>
			<Form {...props}>
				{({ values, disabled }) => {
					return (
						<div className="p-5 bg-white grid grid-cols-1 md:grid-cols-2 gap-8">
							<div className="flex flex-col gap-4">
								<h2 className="bordered">{t("title.student_info")}</h2>
								{(id || profile) && (
									<div className="flex flex-col gap-4 p-5 bg-dark-5">
										{!profile && (
											<Field
												name="active"
												label="field.active"
												disabled={profile || disabled}
												component={Toggle}
											/>
										)}
										<Field
											name="code"
											label="field.code"
											disabled={true}
											component={Text}
										/>
										<Field
											name="password"
											label="field.password"
											type="password"
											disabled={disabled}
											component={Text}
										/>
									</div>
								)}
								<Field
									name="student.class"
									label="field.class"
									disabled={!id || disabled}
									component={Select}
									axio="/const/select/class"
									labelField="current"
								/>
								<Field
									name="register"
									label="field.register"
									disabled={profile || disabled}
									component={Text}
								/>
								<Field
									name="gender"
									label="field.gender"
									disabled={profile || disabled}
									component={Select}
									axio="/const/static/gender"
									axioAdapter={(data) => {
										return data.map(({ _id }) => ({
											value: _id,
											label: t(`const.gender.${_id}`),
										}));
									}}
								/>
								<Field
									name="lastname"
									label="field.lastname"
									disabled={profile || disabled}
									component={Text}
								/>
								<Field
									name="firstname"
									label="field.firstname"
									disabled={profile || disabled}
									component={Text}
								/>
								<Field
									name="phone"
									label="field.phone"
									disabled={disabled}
									component={Text}
								/>
								<Field
									name="address"
									label="field.address"
									disabled={disabled}
									component={Text}
								/>
							</div>
							{!id && !profile && (
								<FieldArray
									name={`parent_items`}
									label={t(`field.parent`)}
									disabled={disabled}
									initial={{
										relation: "",
										firstname: "",
										lastname: "",
										phone: "",
									}}
									renderHeader={({ relation }) =>
										relation && t(`const.relation.${relation}`)
									}
									renderItem={(text, index) => (
										<div className="grid grid-cols-1 gap-2">
											<Field
												name={`parent_items.${index}.relation`}
												label="field.relation"
												disabled={disabled}
												component={Select}
												axio="/const/static/relation"
												axioAdapter={(data) => {
													return data.map(({ _id }) => ({
														value: _id,
														label: t(`const.relation.${_id}`),
													}));
												}}
											/>
											<Field
												name={`parent_items.${index}.lastname`}
												label="field.lastname"
												disabled={disabled}
												component={Text}
											/>
											<Field
												name={`parent_items.${index}.firstname`}
												label="field.firstname"
												disabled={disabled}
												component={Text}
											/>
											<Field
												name={`parent_items.${index}.phone`}
												label="field.phone"
												disabled={disabled}
												component={Text}
											/>
										</div>
									)}
								/>
							)}
							{(id || student_id) && (
								<ParentList
									student_id={id || student_id}
									class_id={class_id}
									disabled={disabled || profile}
									parents={values.student?.parents}
								></ParentList>
							)}
						</div>
					);
				}}
			</Form>
			{!id && !hideStudent && (
				<div className="mt-10">
					<StudentList class_id={class_id}></StudentList>
				</div>
			)}
		</div>
	);
};

export default StudentForm;
