import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { Field, Form } from "../../template";
import { fileValidation, stringValidation } from "../../providers/validations";
import { FileUpload, Select, Text, Toggle } from "../../inputs";
import { renderMedia } from "../../inputs/FileUpload";
import { useApp } from "../../providers/app";
import { useTranslation } from "react-i18next";

const ContentForm = ({ type }) => {
	const { id } = useParams();
	const { user } = useApp();
	const { t } = useTranslation();

	const props = {
		editable: user?.role === "admin",
		isDeletable: user?.role === "admin",
		model: "content",
		id,
		init: {
			type,
			published: true,
			title: "",
			thumb: [],
			content: [],
			grades: [],
		},
		validationSchema: Yup.object().shape({
			title: stringValidation(true),
			thumb: fileValidation(),
			content: fileValidation(),
		}),
		view: (row) => (
			<div className="p-5 bg-white">
				<h2 className="bordered mb-4">{row.title}</h2>
				{renderMedia(row.content?.[0])}
			</div>
		),
	};

	return (
		<Form {...props}>
			{({ disabled, submited }) => {
				return (
					<div className="p-5 bg-white grid grid-cols-1 md:grid-cols-2 gap-8">
						<div className="flex flex-col gap-4">
							<Field
								name="published"
								label="field.published"
								disabled={disabled}
								component={Toggle}
							/>
							<Field
								name="title"
								label="field.title"
								disabled={disabled}
								component={Text}
							/>
							<Field
								name="grades"
								label="field.grade"
								disabled={disabled}
								component={Select}
								items={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i) => ({
									value: i,
									label: i,
								}))}
								isMulti={true}
								isSearchable={false}
							/>
							<Field
								name="lesson"
								label="field.lesson"
								disabled={disabled}
								component={Select}
								axio="/const/static/lesson"
								axioAdapter={(data) => {
									return data.map(({ _id }) => ({
										value: _id,
										label: t(`const.lesson.${_id}`),
									}));
								}}
							/>
						</div>
						<div className="flex flex-col gap-4">
							<Field
								name="thumb"
								label="field.thumb"
								disabled={disabled}
								submited={submited}
								component={FileUpload}
								root="thumb"
							/>
							<Field
								name="content"
								label="field.content"
								disabled={disabled}
								submited={submited}
								component={FileUpload}
								root="content"
								accept={
									type === "lesson"
										? { "video/mp4": [".mp4"] }
										: { "application/pdf": [".pdf"] }
								}
								limit={200}
							/>
						</div>
					</div>
				);
			}}
		</Form>
	);
};

export default ContentForm;
