import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { translateError } from "../../providers/alert";
import { Loader } from "../../template";
import { mainApi } from "../../providers/api";
import { startOfDay } from "date-fns";
import { DatePicker } from "../../inputs";
import { DEBUG } from "../../defines";

const TaskDashboard = () => {
	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);
	const [date, setDate] = useState(
		startOfDay(DEBUG ? new Date("2023-04-10") : new Date())
	);
	const [tasks, setTasks] = useState({});
	const [classes, setClasses] = useState([]);

	useEffect(() => {
		if (date) {
			const search = async () => {
				try {
					setLoading(true);
					if (!date) throw new Error("Хайлтын утга оруулна уу");

					const response = await mainApi({
						url: "/task/dashboard",
						method: "POST",
						data: {
							date,
						},
					});

					setTasks(response.data);
				} catch (error) {
					translateError(error);
				} finally {
					setLoading(false);
				}
			};

			if (date) search();
		}
	}, [date]);

	useEffect(() => {
		const getClass = async () => {
			try {
				setLoading(true);

				const response = await mainApi({
					url: "/const/select/class-desc",
				});

				setClasses(response.data);
			} catch (error) {
				translateError(error);
			} finally {
				setLoading(false);
			}
		};

		getClass();
	}, []);

	return (
		<div className="relative">
			<h2 className="bordered">{t("menu.task")}</h2>

			{loading && <Loader></Loader>}
			<div className="grid grid-cols-6 gap-5 mt-5">
				<DatePicker
					value={date}
					onChange={(e) => setDate(e)}
					placeholder={t("field.date")}
				></DatePicker>
			</div>
			<div className="mt-5 flex flex-col gap-2">
				{classes.map(({ _id, current }) => (
					<div key={_id} className="p-2 bg-white text-sm flex gap-2">
						<div className="w-12 border-r shrink-0">{current}</div>
						<div className="flex flex-col gap-5">
							{Array.isArray(tasks[_id]) &&
								tasks[_id].map(({ _id, lesson, teacher, description }) => (
									<div key={_id}>
										<div className="uppercase">
											{t(`const.lesson.${lesson}`)} /{teacher}/
										</div>
										<div className="text-xs">{description}</div>
									</div>
								))}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default TaskDashboard;
