import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { Field, FieldArray, Form } from "../../template";
import {
	selectValidation,
	stringValidation,
} from "../../providers/validations";
import { DatePicker, FileUpload, Select, Tabs, Text } from "../../inputs";
import { useApp } from "../../providers/app";
import { useTranslation } from "react-i18next";
import View from "./View";

const PollForm = () => {
	const { id } = useParams();
	const { user } = useApp();
	const { t } = useTranslation();

	const props = {
		editable: user.role === "admin",
		isDeletable: user.role === "admin",
		model: "poll",
		id,
		init: {
			title: "",
			start: null,
			end: null,
			roles: [],
			type: "",
			class: null,
			total: 0,
		},
		validationSchema: Yup.object().shape({
			start: stringValidation(true),
			end: stringValidation(true),
			title: stringValidation(true),
			type: stringValidation(true),
			options: Yup.array().of(
				Yup.object().shape({
					text: stringValidation(true),
				})
			),
		}),
		beforeSubmit: (values) => {
			if (values.type === "class" && !values.class)
				throw new Error("Анги сонгоно уу");
			if (!values.options || values.options?.length === 0)
				throw new Error("Сонголт оруулна уу");

			return values;
		},
		view: (row) => <View {...row}></View>,
	};

	return (
		<Form {...props}>
			{({ disabled, values }) => {
				return (
					<div className="p-5 bg-white grid grid-cols-1 md:grid-cols-2 gap-8">
						<div className="flex flex-col gap-4">
							<h2 className="bordered">{t("title.poll_info")}</h2>
							<Field
								name="type"
								label="field.type"
								disabled={disabled}
								component={Tabs}
								axio="/const/static/sigment_type"
								axioAdapter={(data) => {
									return data.map(({ _id }) => ({
										value: _id,
										label: t(`const.sigment_type.${_id}`),
									}));
								}}
							/>
							{values.type === "class" && (
								<Field
									key={values.type}
									name="class"
									isMulti={true}
									label="field.class"
									disabled={disabled}
									component={Select}
									axio="/const/select/class"
									labelField="current"
								/>
							)}
							<Field
								name="roles"
								label="field.role"
								disabled={disabled}
								component={Select}
								isMulti={true}
								items={["student", "parent", "teacher"].map((role) => ({
									value: role,
									label: t(`const.role.${role}`),
								}))}
							/>
							<Field
								name="title"
								label="field.title"
								disabled={disabled}
								component={Text}
								type="textarea"
							/>
							<Field
								name="start"
								label="field.start"
								disabled={disabled}
								component={DatePicker}
							/>
							<Field
								name="end"
								label="field.end"
								disabled={disabled}
								component={DatePicker}
							/>
						</div>
						<div className="flex flex-col gap-4">
							<FieldArray
								name="options"
								label="field.options"
								disabled={disabled}
								initial={{ text: "", voted: 0 }}
								renderItem={(text, index) => (
									<div className="grid grid-cols-1 md:grid-cols-3 gap-2">
										<div className="col-span-2">
											<Field
												name={`options.${index}.text`}
												label="field.option"
												disabled={disabled}
												component={Text}
												type="textarea"
												rows={7}
											/>
										</div>
										{user.role === "admin" && (
											<Field
												name={`options.${index}.voted`}
												label="field.voted"
												disabled={disabled}
												component={Text}
												type="number"
											/>
										)}
										<div className="col-span-3 flex gap-2">
											<div className="flex-1">
												<Field
													name={`options.${index}.thumb`}
													label="field.thumb"
													disabled={disabled}
													component={FileUpload}
													root="poll"
													limit={1}
													size={3}
												/>
											</div>
											<div className="flex-1">
												<Field
													name={`options.${index}.attachment`}
													label="field.attachment"
													disabled={disabled}
													component={FileUpload}
													root="poll"
													limit={1}
													size={3}
													accept={{ "application/pdf": [".pdf"] }}
												/>
											</div>
										</div>
									</div>
								)}
							/>
						</div>
					</div>
				);
			}}
		</Form>
	);
};

export default PollForm;
