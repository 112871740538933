import { useTranslation } from "react-i18next";

const ContactDetails = () => {
	const { t } = useTranslation();

	return (
		<div className="text-dark-80">
			<h2>{t("label.school_name")}</h2>
			<div className="flex flex-col">
				<div>{t("footer.address")}</div>
				<div>
					{t("footer.phone")}: <a href="tel:11326078">11326078</a>
				</div>
				<div>
					{t("footer.email")}:{" "}
					<a href="mailto:info@oros3.mn" className="underline">
						info@oros3.mn
					</a>
				</div>
			</div>
		</div>
	);
};

export default ContactDetails;
