import { useTranslation } from "react-i18next";
import LanguageSwitch from "../language/Switch";
import { useApp } from "../providers/app";
import Swal from "sweetalert2";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Field from "../components/Field";
import { useEffect, useState } from "react";
import {
	GetFromStorage,
	RemoveFromStorage,
	SaveToStorage,
} from "../providers/storage";
import axios from "axios";
import sha256 from "crypto-js/sha256";
import Version from "../template/Version";

const REMEMBER_KEY = "rememberedoros3";
const Login = () => {
	const { t } = useTranslation();
	const { login, logout } = useApp();

	const [remembered] = useState(!!GetFromStorage(REMEMBER_KEY));

	useEffect(() => {
		logout();
	}, [logout]);

	const onSubmit = async ({ usercode, password, remember }) => {
		if (remember) {
			SaveToStorage(REMEMBER_KEY, usercode);
		} else {
			RemoveFromStorage(REMEMBER_KEY);
		}

		try {
			const response = await axios({
				url: "/user/login",
				method: "POST",
				data: {
					code: usercode,
					password: sha256(password).toString(),
				},
			});

			return login(response.data);
		} catch (error) {
			return Swal.fire(
				t("label.warning"),
				error.response?.data || error.toString(),
				"error"
			);
		}
	};

	const Validation = Yup.object().shape({
		usercode: Yup.string().required(t("error.required")),
		password: Yup.string().required(t("error.required")),
	});

	return (
		<div className="text-center justify-center w-96 mx-auto">
			<h2>{t("menu.login")}</h2>
			<Formik
				initialValues={{
					usercode: GetFromStorage(REMEMBER_KEY) || "",
					password: "",
					remember: remembered,
				}}
				validationSchema={Validation}
				onSubmit={onSubmit}
			>
				{({}) => (
					<Form className="flex flex-col gap-5 my-10 mt-5">
						<Field
							autoFocus={!remembered}
							name="usercode"
							placeholder={t("field.usercode")}
							className="uppercase"
						/>
						<Field
							autoFocus={remembered}
							name="password"
							placeholder={t("field.password")}
							type="password"
						/>
						<Field
							name="remember"
							type="checkbox"
							label={t("field.remember")}
						/>
						<button type="submit">{t("button.login")}</button>
					</Form>
				)}
			</Formik>
			<div className="flex w-full justify-center">
				<LanguageSwitch />
			</div>
			<Version></Version>
		</div>
	);
};

export default Login;
