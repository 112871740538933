import { useState } from "react";
import { useTranslation } from "react-i18next";
import { RiAddLine } from "react-icons/ri";
import { translateError } from "../providers/alert";
import { CreatedBy, Loader } from "../template";
import { mainApi } from "../providers/api";
import Table from "../list/Table";

const YearControl = () => {
	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);
	const [year, setYear] = useState(0);

	const save = async () => {
		try {
			setLoading(true);
			if (!year) throw new Error("Анги дэвших утга оруулна уу");

			await mainApi({
				url: "/year",
				method: "POST",
				data: {
					amount: year,
				},
			});

			window.location.reload();
		} catch (error) {
			translateError(error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="relative w-full">
			<h2 className="bordered my-5">БҮХ АНГИ дэвшүүлэх</h2>
			<div className="text-sm italic mb-2">
				Энэхүү хэсгийг зөвхөн хичээлийн жилийн эхэнд нэгэн зэрэг бүх ангийг
				дэвшүүлэхэд ашиглах бөгөөд зөвхөн идэвхтэй төлөвт байгаа ангиуд оруулсан
				тоон утгын дагуу анги дэвшинэ.
				<br />
				Анги дэвшүүлсний дараа ямар нэгэн анги 12-оос дээш утгатай болсон бол
				төгсөөд явсан анги гэж үзээд мэдээллийг нь нягтлаж идэвхгүй төлөвт
				оруулна уу.
			</div>
			{loading && <Loader></Loader>}
			<div className="grid grid-cols-6 gap-5">
				<input
					value={year}
					onChange={(e) => setYear(e.target.value)}
					type="number"
					placeholder={t("field.year")}
				></input>

				<button
					onClick={() => {
						save();
					}}
				>
					<RiAddLine className="text-lg mr-2" />
					{t("button.save")}
				</button>
			</div>
			<div className="mt-5">
				<Table
					url={"/year/table"}
					columns={[
						{
							key: "amount",
							label: "field.year",
							sortable: true,
							filter: true,
						},
						{
							label: "field.createdby",
							width: 250,
							render: ({ created, createdby }) => (
								<CreatedBy date={created} user={createdby}></CreatedBy>
							),
						},
					]}
				></Table>
			</div>
		</div>
	);
};

export default YearControl;
