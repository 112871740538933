import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { Field, Form } from "../../template";
import { stringValidation } from "../../providers/validations";
import { Select, Tabs, Text, Toggle } from "../../inputs";
import { useApp } from "../../providers/app";
import { useTranslation } from "react-i18next";

const FeedbackForm = () => {
	const { id } = useParams();
	const { user } = useApp();
	const { t } = useTranslation();

	const props = {
		editable: user.role === "admin",
		isDeletable: user.role === "admin",
		model: "feedback",
		id,
		init: {
			private: false,
			status: "",
			responded: null,
			respond: "",
		},
		validationSchema: Yup.object().shape({
			content: stringValidation(true),
			status: stringValidation(true),
			respond: stringValidation(true),
		}),
	};

	return (
		<Form {...props}>
			{({ disabled }) => {
				return (
					<div className="p-5 bg-white grid grid-cols-1 md:grid-cols-2 gap-8">
						<div className="flex flex-col gap-4">
							<h2 className="bordered">{t("title.feedback_info")}</h2>
							<Field
								name="private"
								label="field.private"
								disabled={disabled}
								component={Toggle}
							/>
							<Field
								name="type"
								label="field.type"
								disabled={disabled}
								component={Tabs}
								axio="/const/static/feedback_type"
								axioAdapter={(data) => {
									return data.map(({ _id }) => ({
										value: _id,
										label: t(`const.feedback_type.${_id}`),
									}));
								}}
							/>
							<Field
								name="status"
								label="field.status"
								disabled={disabled}
								component={Select}
								axio="/const/static/feedback_status"
								axioAdapter={(data) => {
									return data.map(({ _id }) => ({
										value: _id,
										label: t(`const.feedback_status.${_id}`),
									}));
								}}
							/>
							<Field
								name="content"
								label="field.content"
								disabled={disabled}
								component={Text}
								type="textarea"
							/>
							<Field
								name="respond"
								label="field.respond"
								disabled={disabled}
								component={Text}
								type="textarea"
							/>
						</div>
					</div>
				);
			}}
		</Form>
	);
};

export default FeedbackForm;
