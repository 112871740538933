import { useTranslation } from "react-i18next";
import { WEEKDAY, WEEKEND } from "../../defines";

const WEEKENDS = ["sat", "sun"];

const Schedule = ({ teacher, day, index, lesson }) => {
	const { t } = useTranslation();
	return (
		<div className={`flex ${index === 0 ? "" : "mt-2 pt-2 border-t"}`}>
			<div className="w-32 text-secondary-80">
				{WEEKENDS.includes(day) ? WEEKEND[index] : WEEKDAY[index]}
			</div>
			<div className="w-48 text-dark-80">
				{t(lesson ? `const.lesson.${lesson}` : "label.no_info")}
			</div>
			<div className="flex-1 text-dark-60 text-right">{teacher?.fullname}</div>
		</div>
	);
};

export default Schedule;
