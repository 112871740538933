import { useState } from "react";
import { useTranslation } from "react-i18next";
import ClassForm from "../components/class/Form";
import { Tabs } from "../inputs";
import { useApp } from "../providers/app";

const TeacherDashboard = () => {
	const { t } = useTranslation();
	const { user } = useApp();

	const { classes = [] } = user;
	const items = classes.map((c) => ({ value: c._id, label: c.current }));
	const [selected, setSelected] = useState(items[0]?.value);

	if (!selected)
		return (
			<div className="p-5 bg-white text-center text-dark-100">
				{t("label.teacher_noclass")}
			</div>
		);

	return (
		<div className="w-full">
			<ClassForm
				id={selected}
				back={
					<Tabs items={items} value={selected} onChange={setSelected}></Tabs>
				}
			></ClassForm>
		</div>
	);
};

export default TeacherDashboard;
