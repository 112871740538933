import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { Field, FieldArray, Form } from "../../template";
import {
	selectValidation,
	stringValidation,
} from "../../providers/validations";
import { Select, Text, Toggle } from "../../inputs";
import { useApp } from "../../providers/app";
import { useTranslation } from "react-i18next";

const SurveyTemplateForm = ({ type }) => {
	const { id } = useParams();
	const { user } = useApp();
	const { t } = useTranslation();

	const props = {
		editable: user.role === "admin",
		isDeletable: user.role === "admin",
		model: "survey-template",
		id,
		init: {
			name: "",
			questions: [],
		},
		validationSchema: Yup.object().shape({
			name: stringValidation(true),
			questions: Yup.array().of(
				Yup.object().shape({
					question: stringValidation(true),
					type: selectValidation(true),
				})
			),
		}),
	};

	return (
		<Form {...props}>
			{({ disabled }) => {
				return (
					<div className="p-5 bg-white gap-8">
						<h2 className="bordered">{t("title.survey_template_info")}</h2>
						<div className="my-2">
							<Field
								name="name"
								label="field.name"
								disabled={disabled}
								component={Text}
							/>
						</div>
						<FieldArray
							name={`questions`}
							label={t(`field.questions`)}
							disabled={disabled}
							initial={{
								question: "",
								type: "",
								required: true,
								options: [],
							}}
							renderHeader={({ relation }) =>
								relation && t(`const.relation.${relation}`)
							}
							renderItem={(question, index) => (
								<div className="grid grid-cols-4 gap-2">
									<div>
										<Field
											name={`questions.${index}.required`}
											label="field.question_required"
											disabled={disabled}
											component={Toggle}
										/>
										<Field
											name={`questions.${index}.type`}
											label="field.question_type"
											disabled={disabled}
											component={Select}
											axio="/const/static/question_type"
											axioAdapter={(data) => {
												return data.map(({ _id }) => ({
													value: _id,
													label: t(`const.question_type.${_id}`),
												}));
											}}
										/>
									</div>
									<div className="col-span-3">
										<Field
											name={`questions.${index}.question`}
											label="field.question"
											disabled={disabled}
											component={Text}
											type="textarea"
											rows={3}
										/>
									</div>
									{["multiple_choices", "single_choice"].includes(
										question.type
									) && (
										<FieldArray
											name={`questions.${index}.options`}
											label="field.options"
											disabled={disabled}
											initial={""}
											minimal={true}
											renderItem={(option, oIndex) => (
												<div className="w-full">
													<Field
														name={`questions.${index}.options.${oIndex}`}
														placeholder={t("field.option")}
														disabled={disabled}
														component={Text}
													/>
												</div>
											)}
										/>
									)}
								</div>
							)}
						/>
					</div>
				);
			}}
		</Form>
	);
};

export default SurveyTemplateForm;
