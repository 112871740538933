import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { Field, FieldArray, Form } from "../../template";
import { stringValidation } from "../../providers/validations";
import { Select, Text, Toggle } from "../../inputs";
import { useApp } from "../../providers/app";
import { useTranslation } from "react-i18next";
import View from "./View";
import { DAYS } from "../../defines";

const ClassForm = ({ id, back, hideStudent }) => {
	const { class_id } = useParams();
	const { user } = useApp();
	const { t } = useTranslation();

	const props = {
		editable: ["admin", "teacher"].includes(user.role),
		model: "class",
		id: id || class_id,
		init: {
			active: true,
			year: "",
			grade: 0,
			group: "",
		},
		validationSchema: Yup.object().shape({
			year: stringValidation(true),
			grade: stringValidation(true),
			group: stringValidation(true),
		}),
		view: (values) => (
			<View {...values} key={id} id={id} hideStudent={hideStudent} />
		),
		back: back,
	};

	return (
		<div>
			<Form {...props}>
				{({ disabled, values }) => {
					return (
						<div className="p-5 bg-white">
							<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
								<div className="flex flex-col gap-4">
									<Field
										name="active"
										label="field.active"
										disabled={user.role !== "admin" || disabled}
										component={Toggle}
									/>
									<Field
										name="year"
										label="field.year"
										disabled={user.role !== "admin" || disabled}
										component={Text}
									/>
									<Field
										name="grade"
										label="field.grade"
										disabled={disabled}
										component={Text}
										type="number"
									/>
									<Field
										name="group"
										label="field.group"
										disabled={disabled}
										component={Text}
									/>
								</div>
								<div className="flex flex-col gap-4">
									<Field
										key={values.type}
										name="teachers"
										label="field.class_teacher"
										disabled={disabled}
										component={Select}
										isMulti={true}
										axio="/const/select/teacher"
										labelField="fullname"
									/>
									<Field
										name="advanced_lessons"
										label="field.advanced_lessons"
										disabled={disabled}
										component={Select}
										isMulti={true}
										axio="/const/static/lesson"
										axioAdapter={(data) => {
											return data.map(({ _id }) => ({
												value: _id,
												label: t(`const.lesson.${_id}`),
											}));
										}}
									/>
								</div>
							</div>
							<div className="w-full mt-10">
								<div>{t("field.schedule")}</div>
								<div className="flex gap-4 overflow-scroll">
									{DAYS.map((day) => {
										return (
											<div
												key={day}
												className="mb-5 flex-1"
												style={{ minWidth: 300 }}
											>
												<FieldArray
													renderHeader={({}, index) =>
														`${t(`field.${day}`)} ${index + 1} цаг`
													}
													name={`schedule.${day}`}
													label={t(`field.${day}`)}
													disabled={disabled}
													initial={{ lesson: "", teacher: null }}
													renderItem={(text, index) => (
														<div className="grid grid-cols-1 gap-2">
															<Field
																name={`schedule.${day}.${index}.lesson`}
																label="field.lesson"
																disabled={disabled}
																component={Select}
																axio="/const/static/lesson"
																axioAdapter={(data) => {
																	return data.map(({ _id }) => ({
																		value: _id,
																		label: t(`const.lesson.${_id}`),
																	}));
																}}
															/>
															<Field
																name={`schedule.${day}.${index}.teacher`}
																label="field.teacher"
																disabled={disabled}
																component={Select}
																axio="/const/select/teacher"
																labelField="fullname"
															/>
														</div>
													)}
												/>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					);
				}}
			</Form>
		</div>
	);
};

export default ClassForm;
