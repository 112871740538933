import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { Field, Form } from "../../template";
import { arrayValidation, stringValidation } from "../../providers/validations";
import { DatePicker, FileUpload, Select, Text } from "../../inputs";
import { useApp } from "../../providers/app";
import { useTranslation } from "react-i18next";
import { startOfDay } from "date-fns";
import { renderMedia } from "../../inputs/FileUpload";

const TaskForm = () => {
	const { id } = useParams();
	const { user } = useApp();
	const { t } = useTranslation();

	const supervisor = ["admin", "teacher"].includes(user.role);

	const props = {
		editable: supervisor,
		isDeletable: supervisor,
		model: "task",
		id,
		init: {
			class: [],
			lesson: "",
			description: "",
			date: startOfDay(new Date()),
		},
		validationSchema: Yup.object().shape({
			class: arrayValidation(true),
			lesson: stringValidation(true),
			description: stringValidation(true),
		}),
		view: ({ lesson, description, media, _date }) => (
			<div className="p-5 bg-white">
				<h2 className="bordered mb-4">
					{_date} {t(`const.lesson.${lesson}`)}
				</h2>
				<div className="">{description}</div>
				{renderMedia(media?.[0])}
			</div>
		),
	};

	return (
		<Form {...props}>
			{({ submited, disabled, values }) => {
				return (
					<div className="p-5 bg-white grid grid-cols-1 md:grid-cols-2 gap-8">
						<div className="flex flex-col gap-4">
							<Field
								key={values.type}
								name="class"
								label="field.class"
								disabled={disabled}
								component={Select}
								isMulti={true}
								axio="/const/select/class"
								labelField="current"
							/>
							<Field
								name="lesson"
								label="field.lesson"
								disabled={disabled}
								component={Select}
								axio="/const/static/lesson"
								axioAdapter={(data) => {
									return data.map(({ _id }) => ({
										value: _id,
										label: t(`const.lesson.${_id}`),
									}));
								}}
							/>
							<Field
								name="date"
								label="field.date"
								disabled={disabled}
								component={DatePicker}
							/>
							<Field
								name="description"
								label="menu.task"
								disabled={disabled}
								component={Text}
								type="textarea"
								rows={8}
							/>
						</div>
						<div className="flex flex-col gap-4">
							<Field
								name="media"
								label="field.attachment"
								disabled={disabled}
								submited={submited}
								component={FileUpload}
								root="task"
								limit={10}
								count={10}
							/>
						</div>
					</div>
				);
			}}
		</Form>
	);
};

export default TaskForm;
