import { useTranslation } from "react-i18next";
import { HiPlus } from "react-icons/hi";
import { Link } from "react-router-dom";
import Table from "../../list/Table";
import { formatMedia, more, translateCell } from "../../providers/format";

const UserList = () => {
	const { t } = useTranslation();

	return (
		<Table
			defaultFind={{ role: { $in: ["admin", "teacher"] } }}
			url={"/user/table"}
			renderHeader={() => {
				return (
					<div className="flex justify-end">
						<div className="mb-4">
							<Link to="new" className="button">
								{t("button.create_teacher")}
								<HiPlus className="text-lg ml-1" />
							</Link>
						</div>
					</div>
				);
			}}
			columns={[
				{
					key: "profile",
					label: "field.profile",
					render: (row) => formatMedia(row, "profile", "title", "w-24"),
				},
				{
					key: "code",
					label: "field.code",
					sortable: true,
					filter: true,
				},
				{
					key: "lastname",
					label: "field.lastname",
					sortable: true,
					filter: true,
				},
				{
					key: "firstname",
					label: "field.firstname",
					sortable: true,
					filter: true,
				},
				{
					key: "phone",
					label: "field.phone",
					sortable: true,
					filter: true,
				},
				{
					key: "teacher.position",
					label: "field.position",
					sortable: true,
					filter: true,
				},
				{
					key: "teacher.origin",
					label: "field.origin",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/static/origin",
						axioAdapter: (data) => {
							return data.map(({ _id }) => ({
								value: _id,
								label: t(`const.origin.${_id}`),
							}));
						},
					},
					render: (row) => translateCell(row, t, "origin", "teacher.origin"),
				},
				{
					key: "teacher.department",
					label: "field.department",
					sortable: true,
					filter: {
						type: "select",
						axio: "/const/static/department",
						axioAdapter: (data) => {
							return data.map(({ _id }) => ({
								value: _id,
								label: t(`const.department.${_id}`),
							}));
						},
					},
					render: (row) =>
						translateCell(row, t, "department", "teacher.department"),
				},
				{
					key: "featured",
					label: "field.featured",
					render: ({ featured }) => (featured ? "Онцлох" : ""),
				},
				{
					key: "priority",
					label: "field.priority",
					sortable: true,
					filter: { type: "number" },
				},
				{ render: ({ _id }) => more(`view/${_id}`) },
			]}
		></Table>
	);
};

export default UserList;
